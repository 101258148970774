h1,
h2,
h3 {
  font-family: "GetVoIP Grotesque";
}
h2 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 30px;
  text-transform: uppercase;
  @media screen and (max-width: $sm) {
    font-size: 2.1rem;
    line-height: 2.4rem;
    margin-bottom: 41px;
  }
}
h3 {
  font-weight: 700;
  font-size: 2.4rem;
}
h4 {
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.2rem;
  margin-bottom: 22px;
}
.page-title {
  text-transform: uppercase;
  font-size: 48px;
  margin-bottom: 25px;
  @media screen and (max-width: $sm) {
    font-size: 2.7rem;
    line-height: 2.4rem;
  }
}
.page-title-h2 {
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 34px;
  margin-bottom: 25px;
  @media screen and (max-width: $sm) {
    font-size: 2.1rem;
    line-height: 2.4rem;
    margin-bottom: 35px;
  }
}
.link {
  color: #000;
  text-decoration: none;
  font-size: 1.7rem;
  line-height: 2.2rem;
  span {
    color: $accent-color;
  }
  &:hover {
    color: $accent-color;
  }
}
.overflow {
  overflow: visible !important;
}
sub,
sup {
  position: relative;
  font-size: 65%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
p {
  line-height: 1.6;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.flex {
  display: flex;
}
.jsb {
  justify-content: space-between;
}
.ac {
  align-items: center;
}
.ab {
  align-items: flex-end;
}
.fdr {
  flex-direction: row-reverse;
}

.fw {
  flex-wrap: wrap;
}
.j-start {
  justify-content: flex-start;
}

.header {
  flex-shrink: 0;
}
.page_main {
  flex: 1 0 auto;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  @media screen and (max-width: 980px) {
    padding: 0 20px;
  }
}

.page-return__item {
  transition: all 0.3s ease;
  color: $accent-color;
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  text-decoration: none;
  margin-bottom: 50px;
  &:hover {
    .page-return__item-icon {
      background: url(../img/light/icon-back-h.svg) left center no-repeat;
      background-size: contain;
    }
  }
  &-icon {
    margin-right: 15px;
    background: url(../img/light/icon-back.svg) left center no-repeat;
    background-size: contain;
    display: block;
    width: 44px;
    height: 16px;
  }
}

.list-unstyle {
  list-style: none;
  li {
    margin-bottom: 10px;
  }
}
.col3 {
  width: 30%;
  @media screen and (max-width: $sm) {
    width: 100%;
    margin-bottom: 30px;
    // &:not(:last-child) {
    //   margin-bottom: 30px;
    // }
  }
}

.page-txt {
  margin-top: 87px;
  margin-bottom: 87px;
  @media screen and (max-width: $sm) {
    margin-top: 66px;
    margin-bottom: 66px;
  }
  .margin-txt {
    margin-left: 231px;
    margin-right: 118px;
    margin-bottom: 35px;
    @media screen and (max-width: $sm) {
      margin-left: 50px;
      margin-right: 0;
    }
    & + h2 {
      margin-top: 54px;
      @media screen and (max-width: $sm) {
        margin-top: unset;
      }
    }
  }
  ul {
    padding-left: 20px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  h2 {
    text-transform: none;
    font-size: 3rem;
    max-width: 70%;
    @media screen and (max-width: $sm) {
      font-size: 2.1rem;
      line-height: 2.4rem;
      max-width: 100%;
      margin-bottom: 22px;
    }
  }
  p {
    margin-bottom: 20px;
  }
}
.highlight {
  font-size: 2.4rem;
  line-height: 36px;
  @media screen and (max-width: $sm) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}
.hidden {
  transition: all 0.2s;
  opacity: 0;
  height: 0;
  margin: 0 !important;
}

@media screen and (min-width: $lg) {
  .mobile {
    display: none !important;
  }
}
@media screen and (max-width: $sm) {
  .mobile {
    display: block;
  }
  .mobile.btn {
    display: inline-block;
  }
  .hide-mobile {
    display: none !important;
  }
}
@media screen and (max-width: $sm) {
  main {
    margin-top: 120px;
  }
}

@media screen and (max-width: 945px) and (orientation: landscape) {
  .hide-mobile {
    display: none !important;
  }
}

.col12 {
  width: 100%;
  margin-bottom: 44px;
}
.wide-page-img {
  img {
    max-width: 100%;
    border-radius: 64px;
    // margin-top: 44px;
    margin-bottom: 44px;
    @media screen and (max-width: $sm) {
      width: 100%;
      min-height: 210px;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 0;
      max-height: 70vh;
    }
  }
  @media screen and (max-width: $sm) {
    margin-left: -20px;
    margin-right: -20px;
  }
}
