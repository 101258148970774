.section-related {
  .product_list {
    flex-wrap: nowrap;

    @media screen and (max-width: $sm) {
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
  }
  .product-item__inner:not(:last-child) {
    margin-right: 47px;
    @media screen and (max-width: $sm) {
      margin-right: 0;
    }
  }
  h2 {
    margin-bottom: 48px;
    @media screen and (max-width: $sm) {
      margin-bottom: 41px;
    }
  }
  .product-item__inner {
    margin-bottom: 46px;
    @media screen and (max-width: $sm) {
      margin-bottom: 41px;
    }
  }
  .btn-more + h2 {
    margin-top: 87px;
    @media screen and (max-width: $sm) {
      margin-top: 64px;
    }
  }
}
