.form__checkbox {
  margin-bottom: 37px;
  @media screen and (max-width: $sm) {
    height: 20px;
    margin-bottom: 18px;
  }
  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  label {
    position: relative;
    padding-left: 35px;
    font-size: 1.2rem;
    @media screen and (max-width: $sm) {
      font-size: 10px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 18px;
      height: 18px;
      background: #fff;
      border: 1px solid #fff;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 4px;
      @media screen and (max-width: $sm) {
        top: 0;
        transform: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url(../img/icon-check.svg);
      opacity: 0;
      left: 3px;
      top: 0px;
      @media screen and (max-width: $sm) {
        top: 3px;
      }
    }
  }
  input:checked + label::after {
    opacity: 1;
  }
}
