.irs--flat {
  height: 0;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  background-color: transparent;
  background: url(../img/ellipse.svg) 10% 0% no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  font-size: 0 !important;
  color: #000;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  // padding-bottom: 40px;
}
.irs--flat .irs-to {
  background: url(../img/ellipse.svg) 95% 0% no-repeat;
  background-size: cover;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  display: none;
}
.irs--flat {
  // transform: translateY(5px);
}
.irs--flat .irs-line,
.irs--flat .irs-bar {
  height: 0;
}
.irs--flat .irs-handle {
  display: none;
}
.irs--flat .irs-min,
.irs--flat .irs-max {
  display: none;
}
.custom-range-slider {
  position: absolute;
  width: 90%;
  bottom: 7px;
  left: 5%;
}
