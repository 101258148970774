.page-breadcrumbs {
  margin: 35px 0 38px;
  &__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
    a {
      text-decoration: none;
      color: #000;
    }
    span {
      color: $accent-color;
    }
    &:not(:last-child):after {
      position: absolute;
      top: 0;
      right: 7px;
      content: "/";
      font-size: 1.4rem;
    }
  }
}
.page-breadcrumbs + .page-return__item {
  margin-top: -17px;
}
