.btn {
  border-radius: 34px;
  background: $accent-color;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.2rem;
  padding: 20px 57px;
  text-decoration: none;
  border: none;
  display: inline-block;
  transition: all 0.3s ease;
  font-family: "SF Pro Display", sans-serif;
  @media screen and (max-width: $sm) {
    padding: 10px 50px;
    font-size: 1.4rem;
  }
  cursor: pointer;
  &:hover {
    background: $accent-hover;
  }
  &-sm {
    padding: 10px 49px;
    @media screen and (max-width: $sm) {
      padding: 10px 50px;
    }
  }
  &-lg {
    font-size: 2rem;
  }
  &-border {
    border: 1px solid #000000;
    border-radius: 42px;
    background: none;
    color: #000;
    padding: 10px 48px;
    font-size: 1.5rem;
    line-height: 2.2rem;
    @media screen and (max-width: 980px) {
      padding: 10px 28px;
    }
    &:hover {
      background: transparent;
      color: $accent-color;
      border-color: $accent-color;
    }
  }
  &-block {
    width: 100%;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.btn-search {
  font-size: 0;
  display: inline-block;
  flex-grow: 0;
  width: 68px;
  height: 68px;
  border-radius: 14px;
  padding: 0;
  background-image: url(../img/icon-lense.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  flex-shrink: 0;
  font-size: 0 !important;
  @media screen and (max-width: $sm) {
    width: 48px;
    height: 48px;
  }
  &:hover {
    background-image: url(../img/icon-lense.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
