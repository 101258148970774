@import "vars";
@import "fonts";

::placeholder {
  color: #666;
}

select {
  -webkit-appearance: none;
}
.js-custom-select {
  visibility: hidden;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 10px;
}
html,
body {
  height: 100%;
}
body {
  min-width: 320px;
  position: relative;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 1.4rem;
  overflow-x: hidden;
  color: black;
  display: flex;
  flex-direction: column;
  flex: 1;
  @media screen and (max-width: $sm) {
    font-size: 1.2rem;
  }
  input,
  textarea {
    border: #666 1px solid;
    outline: none;
    &:focus:required:invalid {
      border-color: red;
    }
    &:required:valid {
      border-color: green;
    }
  }
}
.events-none {
  pointer-events: none;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  &.img-width {
    width: 100%;
  }
}

.text-center {
  text-align: center;
}

b,
strong {
  font-weight: bold;
  font-family: "SF Pro Display", sans-serif;
}

a {
  color: $accent-color;
  transition: all 0.3s ease;
}
.img-responsive {
  max-width: 100%;
}
.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.footer {
  flex-shrink: 0;
}

@media screen and (max-width: $sm) {
  .flex {
    flex-wrap: wrap;
    flex-direction: column;
  }
  img {
    max-width: 100%;
  }
}
@import "./_components/common";
@import "./_components/section";
@import "./_components/burger";
@import "./_components/burger-menu";
@import "./_components/buttons";
@import "./_components/filter";
@import "./_components/front-page";
@import "./_components/page-about";
@import "./_components/page-product";
@import "./_components/product";
@import "./_components/related";
@import "./_components/custom-select";
@import "./_components/feedback";
@import "./_components/breadcrumbs";
@import "./_components/checkbox";
@import "./_components/gallery";
@import "./_components/order-form";
@import "./_components/contacts";
@import "./_components/towers";
@import "./_components/projects";
@import "./_components/ion-range";
@import "./_components/topnav";
@import "./_components/modal";
@import "./_components/header";
@import "./_components/footer";
@import "./_components/dark-theme";
