.header__top-nav {
  display: flex;
  justify-content: space-between;
  list-style: none;

  @media screen and (max-width: $sm) {
    display: none;
  }
  li {
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1;
    flex-grow: 1;
    padding: 0 10px;
    position: relative;
    width: 100%;
    @media screen and (max-width: $md) {
      font-size: 1.5rem;
      padding: 0 5px;
    }
    &:not(:last-child) {
      margin-right: 15px;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      border-top: 1px solid $line-color;
      text-decoration: none;
      color: #000;
      width: 100%;
      display: block;
      padding: 17px 0 0;
      position: relative;

      &:before {
        content: "";
        transition: all 0.3s ease;
        position: absolute;
        top: -1px;
        left: 0;
        height: 3px;
        width: 0;
        background: $accent-color;
        @media screen and (max-width: $sm) {
          display: none;
        }
      }
      &.active,
      &:hover {
        &:before {
          width: 100%;
        }
      }
      &.sub {
        background: url(../img/light/arrow-down.svg) 100% 19px no-repeat;
      }
    }
    .submenu {
      list-style: none;
      display: none;
      position: absolute;
      z-index: 3;
      padding: 15px 0 10px 0;
      margin-left: -10px;
      width: 100%;
      @media screen and (max-width: $md) {
        margin-left: -5px;
      }
      @media screen and (max-width: $sm) {
        position: relative;
        // display: block;
        height: 0;
        opacity: 0;
        transition: all 0.3s;
        margin-left: -20px;
        padding: 0;
      }
      li {
        background: #fff;
        font-size: 1.5rem;
        &:first-child {
          padding-top: 10px;
        }
        &:last-child {
          padding-bottom: 10px;
        }
        a {
          background: none;
          border: none;
          color: #000;
          padding: 8px 0;
          &:before {
            display: none;
          }
          &:hover {
            color: $accent-color;
          }
        }
        &:first-child {
          padding-left: 10px;
          @media screen and (max-width: $md) {
            padding-left: 5px;
          }
        }
        &:last-child {
          padding-right: 10px;
          @media screen and (max-width: $md) {
            padding-right: 5px;
          }
        }
      }
      &.open {
        display: block;

        margin-left: -10px;
        @media screen and (max-width: $sm) {
          margin-left: -20px;
        }
        width: 100%;
        a {
          padding: 10px 0;
          color: #000;
          &:before {
            display: none;
          }
          &:hover {
            color: $accent-color;
          }
        }
      }
    }
    &:hover {
      .submenu {
        opacity: 1;
        height: auto;
        display: block;
      }
    }
    &.active,
    &:hover {
      a {
        color: $accent-color;
        &:before {
          width: 100%;
        }
      }
    }
  }
}
