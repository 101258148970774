.product-slider {
  // margin-top: 60px;
  // margin-bottom: 30px;
  @media screen and (max-width: $sm) {
    margin-top: 20px;
  }
  .slick-slide {
    margin: 0 20px;
    @media screen and (max-width: $md) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: $sm) {
      margin: 0 0 40px;
    }
  }
  .slick-list {
    margin: 0 -17px;
    @media screen and (max-width: $sm) {
      margin: 0;
    }
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: 0;
    background: none;
    border: none;
    font-size: 0;
    width: 24px;
    height: 38px;
    cursor: pointer;
    transform: translateY(-50%);
    background: url(../img/light/arrow-slick.svg) no-repeat;
    background-size: contain;
    @media screen and (max-width: $sm) {
      display: none !important;
    }
    &:hover {
      background: url(../img/light/arrow-slick-hover.svg) no-repeat;
      background-size: contain;
    }
    &.slick-disabled {
      opacity: 0.4;
    }
    &.slick-prev {
      left: -50px;
    }
    &.slick-next {
      right: -70px;
      transform-origin: left;
      transform: translateY(-50%) rotate(180deg);
    }
    @media screen and (max-width: 1130px) {
      &.slick-next {
        right: -40px;
      }
      &.slick-prev {
        left: -20px;
      }
    }
  }
}

.page-category__content-header {
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
  margin-bottom: 57px;
  align-items: center;
  @media screen and (max-width: $sm) {
    margin-bottom: 41px;
    margin-top: 41px;
  }
}

.page-category__content-filters {
  display: flex;
  align-content: center;
  justify-content: center;

  @media screen and (max-width: $sm) {
    flex-direction: column;
    font-size: 1.3rem;
    width: 100%;
  }
}
.page-category__content-filter-title {
  font-size: 1.4rem;
  @media screen and (max-width: $sm) {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
}
.page-category__content-filter-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  margin-left: 25px;
  @media screen and (max-width: $sm) {
    margin-left: 0;
    display: flex;
    // justify-content: space-between;
    justify-content: start;
    font-size: 1.1rem;
  }
  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 25px;
      @media screen and (max-width: $sm) {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: $sm) {
      margin-right: 0px;
      padding: 5px 10px 5px 0;
    }

    a {
      text-decoration: none;
      color: #000;
    }
    &.active {
      a {
        color: $accent-color;
      }
      &:after {
        content: "";
        display: inline-block;
        margin-left: 5px;
        font-size: 11px;
      }
    }
    &.up {
      &:after {
        content: "↓";
      }
    }
    &.down {
      &:after {
        content: "↑";
      }
    }
  }
}

.page-category__content-filter--buttons {
  display: flex;
  align-items: center;
  width: 48px;
  justify-content: space-between;
  & > a,
  & > div {
    width: 18px;
    height: 18px;
    cursor: pointer;
    display: block;
    font-size: 0;
  }
  &-tab {
    background: url(../img/light/icon-filter-tab.svg) 50% 50% no-repeat;
    &.active {
      background: url(../img/light/icon-filter-tab-h.svg) 50% 50% no-repeat;
    }
  }
  &-list {
    background: url(../img/light/icon-filter-list.svg) 50% 50% no-repeat;
    &.active {
      background: url(../img/light/icon-filter-list-h.svg) 50% 50% no-repeat;
    }
  }
}
.product-items {
  .page__filter-inner-row {
    margin-bottom: 60px;
    @media screen and (max-width: $sm) {
      margin-bottom: 41px;
    }
  }
  .slick-track {
    margin-left: 0;
  }
}

.product-item {
  &__inner {
    border-radius: 14px;
    box-sizing: border-box;
    overflow: hidden;
    width: calc((100% / 3) - 32px);
    position: relative;
    @media screen and (max-width: $sm) {
      margin-bottom: 41px;
      width: unset;
    }
    & > a {
      color: #000;
      text-decoration: none;
      display: block;
      height: 100%;
    }
    &:hover {
      .product-item__content {
        border-color: $accent-color;
      }
    }
  }
  &__content {
    padding: 40px 20px 30px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    border: 1px solid $line-color;
    border-top: 0;
  }
  &__title {
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 1.2;
    margin-bottom: 5px;
    color: #000;
    text-decoration: none;
    @media screen and (max-width: $md) {
      font-size: 1.5rem;
      line-height: 1;
    }
  }
  &__gallery {
    width: 100%;
    margin-bottom: -14px;
    img {
      width: 100%;
      height: 207px;
      object-fit: cover;
      border-radius: 14px;
    }
  }
  &__image {
    margin-bottom: -14px;
    img {
      width: 100%;
      border-radius: 14px;
    }
  }
  &_info {
    margin-top: 13px;
  }
  &__tower {
    font-size: 1.4rem;
    line-height: 1.4;
    text-decoration: none;
    display: block;
    margin-top: 5px;
    color: $accent-color !important;
    @media screen and (max-width: $sm) {
      font-size: 1.2rem;
    }
  }
  &__info-item_price {
    font-family: "GetVoIP Grotesque";
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 1;

    @media screen and (max-width: $sm) {
      margin-top: 20px;
      font-size: 1.9rem;
    }
    .per-month {
      font-size: 1.4rem;
      line-height: 1.4;
      text-decoration: none;
      display: block;
      margin-top: 5px;
      color: #3c8aa0;
      font-family: "SF Pro Display", sans-serif;
      font-weight: 400;
    }
    &-m {
      font-size: 1.7rem;
      line-height: 1;
      margin-top: 10px;
      // margin-bottom: 5px;
      span {
        font-size: 1.4rem;
        color: $line-color;
      }
    }
  }
  &__tags {
    list-style: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $sm) {
      justify-content: start;
      justify-content: space-between;
    }
    &-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 11px;
      }

      &-text {
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
      svg {
        @media screen and (max-width: $sm) {
          height: 30px;
        }
      }
    }
  }
  &__data {
    height: 80px;
    @media screen and (max-width: $sm) {
      height: auto;
    }
  }
  &__footer {
    margin-top: 30px;
  }
}

.product_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .product-item__inner {
    margin-bottom: 60px;
    @media screen and (max-width: $sm) {
      margin-bottom: 40px;
      width: 100%;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .btn {
      display: none;
    }
  }
}
.product_list-rows {
  flex-direction: column;
  .product-item__inner {
    width: 100%;
    margin-bottom: 57px;
    display: flex;
    padding: 0;
    border-left: 0;
    position: relative;
    overflow: visible !important;
    & > a {
      height: 100%;
      position: relative;
    }
    .btn {
      display: inline-block;
      text-align: center;
      color: #fff;
    }
    .product-item__action {
      position: absolute;
      top: 22px;
      right: 24px;
      text-align: right;
      .add-fav {
        font-size: 0;
        background: url(../img/light/icon-star.svg) no-repeat;
        display: block;
        width: 24px;
        height: 23px;
        margin-left: auto;
        margin-bottom: 16px;
        cursor: pointer;
        &:hover {
          background: url(../img/light/icon-star-h.svg) no-repeat;
        }
      }
    }
  }
  .product-item__data {
    height: auto;
    margin-bottom: 22px;
  }
  .product-item__image {
    width: 50%;
    margin-bottom: 0;
    margin-right: -14px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      object-fit: cover;
      position: relative;
    }
  }
  .product-item__content {
    padding: 25px 24px 25px 50px;
    width: calc(55% + 14px);
    border-top: 1px solid $line-color;
    border-left: 0;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 0;
  }
  .product-item__title {
    font-size: 1.9rem;
  }
  .product-item__info-item_price {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
  .product-item__tags {
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: 90%;
    li {
      width: 45%;
      //  max-width: 150px;
      margin: 9px 0;
      font-size: 1.6rem;
      .icon {
        display: inline-block;
        display: flex;
        background: #f7f7f7;
        border-radius: 14px;
        width: 56px;
        height: 56px;
        align-items: center;
        justify-content: center;
        margin-right: 13px;
      }
      .product-item__tags-item-text {
        margin-left: 0;
      }
    }
  }
  & + .btn-more {
    margin-bottom: 85px;
  }
}
.product_list-tab {
  &::after {
    content: "";
    width: calc(33.3% - 23.5px);
    @media screen and (max-width: $sm) {
      display: none;
    }
  }
  .product-item__inner {
    width: calc(33.3% - 23.5px);
    @media screen and (max-width: $sm) {
      width: 100%;
    }
  }
  .product-item__info-item_price-m,
  .product-item__action {
    display: none;
  }
  .product-item__image {
    img {
      width: 100%;
      border-radius: 14px;
    }
  }
  @media screen and (max-width: $sm) {
    margin-bottom: 41px;
  }
}

.page-product-content__row {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: $sm) {
    flex-direction: column;
  }
}

.page-product-content__gallery {
  width: 70%;
  position: relative;
  @media screen and (max-width: $md) {
    width: 60%;
  }
  @media screen and (max-width: $sm) {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 36px;
  }
}
.page-product-content__body {
  padding-left: 60px;
  @media screen and (max-width: $sm) {
    padding-left: 0;
    width: 100%;
    .page-product-content__row {
      flex-direction: row;
      align-items: baseline;
      justify-content: start !important;
    }
  }
  .page-product-content__body-header {
    position: relative;
    a {
      display: block;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-top: 8px;
      text-decoration: none;
      @media screen and (max-width: $sm) {
        font-size: 1.2rem;
        margin-top: 0;
      }
    }
    .id {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
    .add-fav {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0;
      background: url(../img/light/icon-star.svg) no-repeat;
      background-size: contain;
      display: block;
      width: 24px;
      height: 23px;
      margin-left: auto;
      margin-bottom: 10px;
      cursor: pointer;
      @media screen and (max-width: $sm) {
        width: 19.05px;
        height: 18px;
      }
      &:hover {
        background: url(../img/light/icon-star-h.svg) no-repeat;
        background-size: contain;
      }
      &.active {
        background: url(../img/light/icon-star-h.svg) no-repeat;
        background-size: contain;
      }
    }
  }
}
