.section-feedback {
  @media screen and (max-width: $sm) {
    margin-top: 68px;
  }
  &-form {
    width: 65%;
    background: #f7f7f7;
    border-radius: 14px;
    padding: 42px 28px;
    margin-right: 50px;
    // margin-top: 30px;
    @media screen and (max-width: $sm) {
      width: calc(100% + 40px);
      border-radius: 0;
      padding: 40px 20px;
      margin-right: -20px;
      margin-left: -20px;
      margin-top: 0;
    }
    &__row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 15px;
      flex-wrap: wrap;
    }
    &__field {
      position: relative;
      width: 48%;
      @media screen and (max-width: $sm) {
        width: 100%;
        margin-bottom: 10px;
      }
      input:focus ~ span {
        display: none;
      }
      span {
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        @media screen and (max-width: $sm) {
          font-size: 1.3rem;
        }
      }
    }

    &__radio {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 980px) {
        justify-content: start;
      }

      input {
        position: absolute;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        width: 0;
        height: 0;
      }
      label {
        border: 1px solid #000;
        border-radius: 42px;
        padding: 10px 30px;
        font-size: 1.3rem;
        line-height: 1;
        height: auto;
        margin-bottom: 10px;
        cursor: pointer;
        @media screen and (max-width: 980px) {
          margin-right: 20px;
        }
      }

      input:checked + label {
        border: 1px solid $accent-color;
        color: $accent-color;
      }
    }
    &__radio-label {
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 1.4;
      margin-bottom: 25px;
      margin-top: 20px;
      display: block;
      position: static;
      @media screen and (max-width: $sm) {
        font-size: 1.4rem;
        margin-bottom: 14px;
      }
    }
    &__checkbox {
      margin-bottom: 37px;
    }
  }
  &-info {
    font-weight: 400;

    @media screen and (max-width: $sm) {
      display: none;
    }
    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      &:hover {
        svg path {
          fill: $accent-color !important;
        }
      }
      &.fav {
        &:hover {
          svg path {
            fill: none !important;
            stroke: $accent-color;
          }
        }
      }
    }
  }
  &-email {
    display: block;
    font-size: 2rem;
    line-height: 1.6;
    margin-bottom: 5px;
    color: $accent-color;
    text-decoration: none;
  }
  &-phone {
    display: block;
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 20px;
    color: #000;
    text-decoration: none;
  }
  p {
    // margin-top: 18px;
    margin-bottom: 15px;
  }
  h3 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.4;
    margin-bottom: 0px;
    font-family: "SF Pro Display", sans-serif;
    @media screen and (max-width: $sm) {
      font-size: 2.1rem;
      line-height: 2.4rem;
      margin-bottom: 12px;
    }
  }
  .flex {
    margin-top: 49px;
    @media screen and (max-width: $sm) {
      margin-top: 20px;
    }
  }

  .input_text {
    background: #ffffff;
    border-radius: 42px;
    border: none;
    padding: 10px 20px;
    width: 100%;
  }
}
