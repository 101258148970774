@charset "UTF-8";
@font-face {
  font-family: "SF Pro Display";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/SFPro/SFProDisplay-Regular.eot");
  src: url("../fonts/SFPro/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/SFPro/SFProDisplay-Regular.woff") format("woff"), url("../fonts/SFPro/SFProDisplay-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/SFPro/SFProDisplay-Bold.eot");
  src: url("../fonts/SFPro/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SFPro/SFProDisplay-Bold.woff") format("woff"), url("../fonts/SFPro/SFProDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/SFPro/SFProDisplay-Semibold.eot");
  src: url("../fonts/SFPro/SFProDisplay-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/SFPro/SFProDisplay-Semibold.woff") format("woff"), url("../fonts/SFPro/SFProDisplay-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "GetVoIP Grotesque";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/getvoip/GetVoIPGrotesque.eot");
  src: url("../fonts/getvoip/GetVoIPGrotesque.eot?#iefix") format("embedded-opentype"), url("../fonts/getvoip/GetVoIPGrotesque.woff") format("woff"), url("../fonts/getvoip/GetVoIPGrotesque.ttf") format("truetype");
}
::placeholder {
  color: #666;
}

select {
  -webkit-appearance: none;
}

.js-custom-select {
  visibility: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

html,
body {
  height: 100%;
}

body {
  min-width: 320px;
  position: relative;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 1.4rem;
  overflow-x: hidden;
  color: black;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media screen and (max-width: 680px) {
  body {
    font-size: 1.2rem;
  }
}
body input,
body textarea {
  border: #666 1px solid;
  outline: none;
}
body input:focus:required:invalid,
body textarea:focus:required:invalid {
  border-color: red;
}
body input:required:valid,
body textarea:required:valid {
  border-color: green;
}

.events-none {
  pointer-events: none;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-responsive.img-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

b,
strong {
  font-weight: bold;
  font-family: "SF Pro Display", sans-serif;
}

a {
  color: #3c8aa0;
  transition: all 0.3s ease;
}

.img-responsive {
  max-width: 100%;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.footer {
  flex-shrink: 0;
}

@media screen and (max-width: 680px) {
  .flex {
    flex-wrap: wrap;
    flex-direction: column;
  }
  img {
    max-width: 100%;
  }
}
h1,
h2,
h3 {
  font-family: "GetVoIP Grotesque";
}

h2 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media screen and (max-width: 680px) {
  h2 {
    font-size: 2.1rem;
    line-height: 2.4rem;
    margin-bottom: 41px;
  }
}

h3 {
  font-weight: 700;
  font-size: 2.4rem;
}

h4 {
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.2rem;
  margin-bottom: 22px;
}

.page-title {
  text-transform: uppercase;
  font-size: 48px;
  margin-bottom: 25px;
}
@media screen and (max-width: 680px) {
  .page-title {
    font-size: 2.7rem;
    line-height: 2.4rem;
  }
}

.page-title-h2 {
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 34px;
  margin-bottom: 25px;
}
@media screen and (max-width: 680px) {
  .page-title-h2 {
    font-size: 2.1rem;
    line-height: 2.4rem;
    margin-bottom: 35px;
  }
}

.link {
  color: #000;
  text-decoration: none;
  font-size: 1.7rem;
  line-height: 2.2rem;
}
.link span {
  color: #3c8aa0;
}
.link:hover {
  color: #3c8aa0;
}

.overflow {
  overflow: visible !important;
}

sub,
sup {
  position: relative;
  font-size: 65%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

p {
  line-height: 1.6;
}
p:not(:last-child) {
  margin-bottom: 20px;
}

.flex {
  display: flex;
}

.jsb {
  justify-content: space-between;
}

.ac {
  align-items: center;
}

.ab {
  align-items: flex-end;
}

.fdr {
  flex-direction: row-reverse;
}

.fw {
  flex-wrap: wrap;
}

.j-start {
  justify-content: flex-start;
}

.header {
  flex-shrink: 0;
}

.page_main {
  flex: 1 0 auto;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 980px) {
  .container {
    padding: 0 20px;
  }
}

.page-return__item {
  transition: all 0.3s ease;
  color: #3c8aa0;
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  text-decoration: none;
  margin-bottom: 50px;
}
.page-return__item:hover .page-return__item-icon {
  background: url(../img/light/icon-back-h.svg) left center no-repeat;
  background-size: contain;
}
.page-return__item-icon {
  margin-right: 15px;
  background: url(../img/light/icon-back.svg) left center no-repeat;
  background-size: contain;
  display: block;
  width: 44px;
  height: 16px;
}

.list-unstyle {
  list-style: none;
}
.list-unstyle li {
  margin-bottom: 10px;
}

.col3 {
  width: 30%;
}
@media screen and (max-width: 680px) {
  .col3 {
    width: 100%;
    margin-bottom: 30px;
  }
}

.page-txt {
  margin-top: 87px;
  margin-bottom: 87px;
}
@media screen and (max-width: 680px) {
  .page-txt {
    margin-top: 66px;
    margin-bottom: 66px;
  }
}
.page-txt .margin-txt {
  margin-left: 231px;
  margin-right: 118px;
  margin-bottom: 35px;
}
@media screen and (max-width: 680px) {
  .page-txt .margin-txt {
    margin-left: 50px;
    margin-right: 0;
  }
}
.page-txt .margin-txt + h2 {
  margin-top: 54px;
}
@media screen and (max-width: 680px) {
  .page-txt .margin-txt + h2 {
    margin-top: unset;
  }
}
.page-txt ul {
  padding-left: 20px;
  line-height: 1.6;
  margin-bottom: 20px;
}
.page-txt h2 {
  text-transform: none;
  font-size: 3rem;
  max-width: 70%;
}
@media screen and (max-width: 680px) {
  .page-txt h2 {
    font-size: 2.1rem;
    line-height: 2.4rem;
    max-width: 100%;
    margin-bottom: 22px;
  }
}
.page-txt p {
  margin-bottom: 20px;
}

.highlight {
  font-size: 2.4rem;
  line-height: 36px;
}
@media screen and (max-width: 680px) {
  .highlight {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

.hidden {
  transition: all 0.2s;
  opacity: 0;
  height: 0;
  margin: 0 !important;
}

@media screen and (min-width: 1024px) {
  .mobile {
    display: none !important;
  }
}
@media screen and (max-width: 680px) {
  .mobile {
    display: block;
  }
  .mobile.btn {
    display: inline-block;
  }
  .hide-mobile {
    display: none !important;
  }
}
@media screen and (max-width: 680px) {
  main {
    margin-top: 120px;
  }
}
@media screen and (max-width: 945px) and (orientation: landscape) {
  .hide-mobile {
    display: none !important;
  }
}
.col12 {
  width: 100%;
  margin-bottom: 44px;
}

.wide-page-img img {
  max-width: 100%;
  border-radius: 64px;
  margin-bottom: 44px;
}
@media screen and (max-width: 680px) {
  .wide-page-img img {
    width: 100%;
    min-height: 210px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 0;
    max-height: 70vh;
  }
}
@media screen and (max-width: 680px) {
  .wide-page-img {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.page-section {
  margin-top: 27px;
  margin-bottom: 60px;
}
@media screen and (max-width: 680px) {
  .page-section {
    margin-bottom: 68px;
    margin-top: 41px;
  }
}
.page-section .flex .link {
  white-space: nowrap;
  width: 220px;
  flex-shrink: 0;
  margin-bottom: 22px;
}
@media screen and (max-width: 680px) {
  .page-section .flex .link {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 980px) {
  .section-chess {
    overflow: hidden;
  }
  .section-chess .flex {
    flex-wrap: wrap;
  }
}
.section-chess .txt-content {
  width: 50%;
}
@media screen and (max-width: 980px) {
  .section-chess .txt-content {
    padding: 0;
    width: 100%;
  }
}
.section-chess .txt-content ul,
.section-chess .txt-content li,
.section-chess .txt-content p {
  line-height: 1.5;
}
.section-chess .txt-content .subtitle {
  color: #3c8aa0;
  font-size: 2rem;
  margin-bottom: 30px;
}
.section-chess .txt-content .title {
  font-family: "GetVoIP Grotesque";
  font-size: 2.4rem;
  line-height: 30px;
  margin-bottom: 22px;
  text-transform: uppercase;
}
@media screen and (max-width: 680px) {
  .section-chess .txt-content .title {
    font-size: 2.1rem;
    line-height: 2.4rem;
    text-transform: none;
  }
}
.section-chess .txt-img {
  width: 50%;
  margin-left: 46px;
  overflow: hidden;
  border-radius: 64px;
}
.section-chess .txt-img img {
  display: block;
}
@media screen and (max-width: 980px) {
  .section-chess .txt-img {
    width: 100%;
    border-radius: 36px;
    margin-top: 30px;
    margin-bottom: 0px;
    margin-left: 0;
  }
}
@media screen and (max-width: 680px) {
  .section-chess .txt-img img {
    min-height: calc(100vw - 40px);
    object-fit: cover;
  }
}
.section-chess .fdr img {
  float: right;
}
.section-chess .fdr .txt-content {
  margin-left: 25px;
  margin-right: 0;
}
@media screen and (max-width: 980px) {
  .section-chess .fdr .txt-content {
    margin-left: 0;
  }
}
.section-chess .fdr .txt-img {
  margin-left: 0;
  margin-right: 25px;
  padding-right: 0;
}
@media screen and (max-width: 680px) {
  .section-chess .fdr .txt-img {
    margin-right: 0;
    padding-right: 0;
  }
}

.page-nav__hamburger {
  width: 25px;
  height: 15px;
  position: relative;
}

.page-nav__hamburger {
  cursor: pointer;
}
.page-nav__hamburger span {
  display: block;
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #000;
  border-radius: 3px;
  transition: all ease-in-out 0.25s;
}
.page-nav__hamburger:hover span {
  background-color: #3c8aa0;
}

.page-nav__hamburger span:nth-child(1) {
  top: 0px;
}

.page-nav__hamburger span:nth-child(2) {
  top: 5px;
}
@media screen and (max-width: 680px) {
  .page-nav__hamburger span:nth-child(2) {
    width: 60%;
  }
}

.page-nav__hamburger span:nth-child(3) {
  top: 10px;
}

.page-nav__hamburger.active span:nth-child(1) {
  transform: rotate(-45deg);
  margin-bottom: 0;
  margin-top: 5px;
}
.page-nav__hamburger.active span:nth-child(2) {
  display: none;
}
.page-nav__hamburger.active span:nth-child(3) {
  transform: rotate(45deg);
  margin-top: -5px;
}

@media screen and (max-width: 680px) {
  .header.open .main-nav {
    height: calc(100vh - 79px);
    background: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    top: 79px;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    justify-content: space-between;
  }
  .header.open .header__top-nav {
    display: block !important;
    margin: 20px;
  }
  .header.open .header__top-nav li {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    font-size: 1.4rem;
    margin-bottom: 10px;
    background-color: #f7f7f7;
    border-radius: 14px;
    font-weight: bold;
  }
  .header.open .header__top-nav li:first-child {
    padding-left: 20px;
  }
  .header.open .header__top-nav li:last-child {
    padding-right: 20px;
  }
  .header.open .header__top-nav li a {
    border: none;
    padding: 10px 0;
  }
  .header.open .header__top-nav li a.sub {
    background: url(../img/light/arrow-down.svg) 100% 14px no-repeat;
  }
  .header.open .header__top-nav li .submenu li {
    font-weight: normal;
    margin-bottom: 0;
  }
  .header.open .header__top-nav li .submenu li a {
    padding: 5px 0;
  }
  .header.open .header__top-nav li.active > a {
    color: #999;
    background: url(../img/light/arrow-up.svg) 100% 50% no-repeat;
  }
  .header.open .header__top-nav li.active > a::before {
    display: none;
  }
  .header.open .header__top-nav li.active .submenu {
    display: block;
    position: relative;
    margin-left: 0;
    padding-top: 0;
  }
  .header.open .header__top-nav li.active .submenu li {
    padding-left: 0;
    margin-bottom: 0;
  }
  .header.open .add-nav {
    display: none !important;
  }
  .header.open .header_mobile__info {
    display: block;
    margin-left: 20px;
    padding-bottom: 30px;
  }
}
.btn {
  border-radius: 34px;
  background: #3c8aa0;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.2rem;
  padding: 20px 57px;
  text-decoration: none;
  border: none;
  display: inline-block;
  transition: all 0.3s ease;
  font-family: "SF Pro Display", sans-serif;
  cursor: pointer;
}
@media screen and (max-width: 680px) {
  .btn {
    padding: 10px 50px;
    font-size: 1.4rem;
  }
}
.btn:hover {
  background: rgba(60, 138, 160, 0.7);
}
.btn-sm {
  padding: 10px 49px;
}
@media screen and (max-width: 680px) {
  .btn-sm {
    padding: 10px 50px;
  }
}
.btn-lg {
  font-size: 2rem;
}
.btn-border {
  border: 1px solid #000000;
  border-radius: 42px;
  background: none;
  color: #000;
  padding: 10px 48px;
  font-size: 1.5rem;
  line-height: 2.2rem;
}
@media screen and (max-width: 980px) {
  .btn-border {
    padding: 10px 28px;
  }
}
.btn-border:hover {
  background: transparent;
  color: #3c8aa0;
  border-color: #3c8aa0;
}
.btn-block {
  width: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.btn-search {
  font-size: 0;
  display: inline-block;
  flex-grow: 0;
  width: 68px;
  height: 68px;
  border-radius: 14px;
  padding: 0;
  background-image: url(../img/icon-lense.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  flex-shrink: 0;
  font-size: 0 !important;
}
@media screen and (max-width: 680px) {
  .btn-search {
    width: 48px;
    height: 48px;
  }
}
.btn-search:hover {
  background-image: url(../img/icon-lense.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.page__filter-inner-row {
  display: flex;
  align-items: stretch;
  margin-bottom: 46px;
}
@media screen and (max-width: 680px) {
  .page__filter-inner-row {
    flex-direction: column;
    margin-bottom: 0;
  }
}
.page__filter-inner-add-row {
  margin-bottom: 46px;
}
@media screen and (max-width: 680px) {
  .page__filter-inner-add-row {
    margin-bottom: 22px;
  }
}
.page__filter-inner-item {
  min-width: 200px;
  background: #f7f7f7;
  border-radius: 14px;
  padding: 13px 15px 10px 15px;
  flex-shrink: 0;
  width: 25%;
  position: relative;
  height: 68px;
}
@media screen and (max-width: 680px) {
  .page__filter-inner-item {
    margin-bottom: 14px;
    width: 100%;
    height: 70px;
  }
}
.page__filter-inner-item:not(:last-child) {
  width: calc(25% - 20px);
  margin-right: 20px;
}
@media screen and (max-width: 680px) {
  .page__filter-inner-item:not(:last-child) {
    width: 100%;
    margin-bottom: 14px;
  }
}
.page__filter-inner-item--check {
  background: none;
  padding: 0;
}
.page__filter-inner-item--check .page__filter-label {
  margin-bottom: 7px !important;
}
.page__filter-inner .form-options {
  display: flex;
  gap: 10px;
}
.page__filter-inner .filter-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: #f7f7f7;
  border-radius: 14px;
  text-decoration: none;
  font-size: 17px;
  line-height: 22px;
  flex-shrink: 0;
  color: #000;
}
.page__filter-inner .filter-checkbox.active {
  color: #3c8aa0;
}
.page__filter-header {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 1.5rem;
}
@media screen and (max-width: 680px) {
  .page__filter-header {
    font-size: 1.1rem;
    margin-bottom: 22px;
  }
}
.page__filter-header ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: 0;
  padding-left: 0;
}
@media screen and (max-width: 680px) {
  .page__filter-header ul {
    display: grid;
    grid-template-columns: 13% 30% 37% 20%;
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    align-items: center;
    width: 100%;
  }
}
.page__filter-header ul li {
  height: 32px;
}
.page__filter-header ul li:not(:last-child) {
  border-right: 1px solid #9e9e9e;
}
.page__filter-header ul li:not(:last-child) a {
  padding-right: 54px;
}
@media screen and (max-width: 680px) {
  .page__filter-header ul li:not(:last-child) a {
    padding-right: 0px;
  }
}
.page__filter-header ul li:not(:first-child) a {
  padding-left: 54px;
}
@media screen and (max-width: 680px) {
  .page__filter-header ul li:not(:first-child) a {
    padding-left: 0px;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
}
.page__filter-header ul li.active a {
  color: #000;
}
.page__filter-header ul a {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  color: #9e9e9e;
}
.page__filter-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 680px) {
  .page__filter-footer {
    margin-top: 22px;
  }
}
.page__filter-footer-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 680px) {
  .page__filter-footer-buttons {
    flex-direction: column;
  }
}
.page__filter-footer-buttons .btn {
  font-size: 2rem;
}
@media screen and (max-width: 680px) {
  .page__filter-footer-buttons .btn {
    font-size: 1.4rem;
  }
}
.page__filter-footer-result {
  font-size: 1.7rem;
  margin-left: 45px;
  margin-right: auto;
}
@media screen and (max-width: 680px) {
  .page__filter-footer-result {
    font-size: 1.4rem;
    margin-left: 0;
    margin-top: 14px;
  }
}
.page__filter-footer-result a {
  text-decoration: none;
}
.page__filter-footer-clear {
  text-decoration: none;
  color: #000;
  font-size: 1.4rem;
  padding-right: 25px;
  display: flex;
  align-items: center;
  background: url(../img/light/close.svg) 100% 50% no-repeat;
  margin-left: 45px;
}
.page__filter-footer-clear:hover {
  background: url(../img/light/close-h.svg) 100% 50% no-repeat;
}
.page__filter-footer .page-category__filter-open {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 40px;
  cursor: pointer;
}
@media screen and (max-width: 680px) {
  .page__filter-footer .page-category__filter-open {
    position: absolute;
    right: 0;
    top: -28px;
    font-size: 1.1rem;
  }
}
.page__filter-footer .page-category__filter-open.active .icon-arrow-select {
  background: url(../img/light/arrow-up.svg) 100% 50% no-repeat;
}
.page__filter .btn {
  align-self: center;
}
@media screen and (max-width: 680px) {
  .page__filter .btn {
    align-self: start;
    height: 42px;
    font-size: 1.4rem;
  }
}
.page__filter-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f7f7f7;
  border-radius: 14px;
  z-index: 3000;
  padding: 35px 30px;
  border: 1px solid #9e9e9e;
  overflow: hidden;
  min-width: 500px;
  max-width: 510px;
}
@media screen and (max-width: 680px) {
  .page__filter-modal {
    padding: 30px 20px;
    width: 95%;
    min-width: auto;
  }
}
.page__filter-modal .modal-content {
  display: flex;
}
.page__filter-modal ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 2.2rem;
  font-size: 1.7rem;
}
@media screen and (max-width: 680px) {
  .page__filter-modal ul {
    line-height: 1.6rem;
    font-size: 1.4rem;
  }
}
.page__filter-modal ul + ul {
  margin-left: 100px;
}
@media screen and (max-width: 680px) {
  .page__filter-modal ul + ul {
    margin-left: 50px;
  }
}
.page__filter-modal ul li {
  padding: 6px 0px;
}
.page__filter-modal ul li a {
  text-decoration: none;
  color: #000;
}
.page__filter-modal ul li a:hover {
  color: #3c8aa0;
}
.page__filter-modal ul li.active a {
  color: #3c8aa0;
  background: url(../img/light/close.svg) 100% 6px no-repeat;
  background-size: 12px 12px;
  padding-right: 20px;
  display: inline-block;
}
.page__filter-modal ul li.active a:hover {
  background: url(../img/light/close-h.svg) 100% 6px no-repeat;
  background-size: 12px 12px;
}
@media screen and (max-width: 680px) {
  .page__filter-modal ul li.active a {
    background: url(../img/light/close.svg) 100% 4px no-repeat;
    background-size: 11px;
  }
}
.page__filter-modal ul ul {
  margin-top: 3px;
  margin-bottom: 3px;
}
.page__filter-modal ul ul li {
  padding: 6px 15px;
}
.page__filter-modal ul ul li:last-child {
  padding-bottom: 0;
}
.page__filter-modal h4 {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 2rem;
}
.page__filter-modal .close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  height: 25px;
  width: 25px;
  align-items: center;
  background: url(../img/light/close.svg) 100% 50% no-repeat;
}
.page__filter-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
@media screen and (max-width: 680px) {
  .page__filter-modal-buttons {
    justify-content: space-between;
  }
}
.page__filter-modal-buttons .btn {
  padding: 5px 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.page__filter-modal-buttons .page__filter-footer-clear {
  right: 30px;
  position: absolute;
}
@media screen and (max-width: 680px) {
  .page__filter-modal-buttons .page__filter-footer-clear {
    position: unset;
  }
}

.custom-select {
  background: #f7f7f7;
  border-radius: 14px;
  padding: 15px;
}

.page_main .page__filter-finishing {
  width: 180px !important;
  flex-grow: 0 !important;
}
@media screen and (max-width: 680px) {
  .page_main .page__filter-finishing {
    width: unset !important;
    flex-grow: unset !important;
  }
}

@media screen and (max-width: 680px) {
  .custom-select.mobile {
    padding: 0;
  }
}
.page__filter-lot {
  background: none;
  padding: 0;
  width: 25%;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (max-width: 680px) {
  .page__filter-lot {
    margin-bottom: 0px;
    width: 100%;
    align-items: flex-start;
  }
}
.page__filter-lot .page__filter-label {
  padding-top: 15px;
}
.page__filter-lot input[type=text] {
  width: 90%;
}

input[type=text] {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  padding: 10px;
  margin-bottom: 1px;
  line-height: 28px;
  font-size: 1.7rem;
}
@media screen and (max-width: 680px) {
  input[type=text] {
    font-size: 1.5rem;
  }
}

.page__filter-label {
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #9e9e9e;
  display: block;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 680px) {
  .page__filter-label {
    font-size: 1.2rem;
  }
}

.icon-arrow-select {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../img/light/arrow-down.svg) 100% 50% no-repeat;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.page_main .page__filter {
  margin-bottom: 87px;
  margin-top: 30px;
}
@media screen and (max-width: 680px) {
  .page_main .page__filter {
    margin-top: 0;
    margin-bottom: 68px;
  }
}
.page_main .page__filter .page_main .page__filter-inner-row {
  justify-content: space-between;
}
.page_main .page__filter .page__filter-inner-item {
  width: auto;
  min-width: auto;
  flex-grow: 1;
  position: relative;
}
@media screen and (max-width: 680px) {
  .page_main .page__filter .page__filter-inner-item {
    margin-bottom: 14px;
    height: 70px;
    margin-right: 0;
  }
}
.page_main .page__filter-footer-buttons {
  width: auto;
  flex-wrap: wrap;
}
@media screen and (max-width: 680px) {
  .page_main .page__filter-footer-buttons {
    flex-direction: column;
  }
}
.page_main .page__filter-footer-clear {
  margin-left: 0;
}
.page_main .page__filter-footer-result {
  width: auto;
  margin-left: 65px;
  margin-top: 0;
  font-size: 1.7rem;
}
@media screen and (max-width: 680px) {
  .page_main .page__filter-footer-result {
    margin-left: 0 !important;
    font-size: 1.4rem;
  }
}
.page_main .page__filter-footer-result a {
  text-decoration: none;
}

.page-category__label-content .flex {
  justify-content: space-between;
  font-size: 1.7rem;
  margin-top: 3px;
}
@media screen and (max-width: 680px) {
  .page-category__label-content .flex {
    font-size: 1.5rem;
  }
}

.front-page-header {
  margin-top: 42px;
  height: 369px;
  background: linear-gradient(0deg, #eefdfc 0%, #ecfdfc 100%);
  position: relative;
}
@media screen and (max-width: 680px) {
  .front-page-header {
    margin-top: 0;
    height: 140px;
    margin-bottom: 60px;
  }
}
.front-page-header .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: url(../img/light.png) -170px 0 no-repeat;
  position: relative;
  z-index: 2;
  transform: translateX(-30%);
}
@media screen and (max-width: 680px) {
  .front-page-header .container {
    transform: none;
    background-position: 50px 100%;
    background-size: auto 100%;
    justify-content: start;
  }
}
.front-page-header:after {
  content: "";
  display: block;
  height: 100%;
  width: 50%;
  position: absolute;
  background: #fff;
  left: 50%;
  top: 0;
  z-index: 1;
}
.front-page-header .front-page-header-title {
  transform: translateX(30%);
  position: relative;
  width: 100%;
}
@media screen and (max-width: 680px) {
  .front-page-header .front-page-header-title {
    transform: none;
  }
}
.front-page-header h1 {
  font-family: "GetVoIP Grotesque";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 5.9rem;
  line-height: 1;
  letter-spacing: 0.05em;
  text-align: left;
  padding-left: 210px;
  display: inline-block;
  margin-top: 40px;
}
@media screen and (max-width: 980px) {
  .front-page-header h1 {
    padding-left: 0;
    font-size: 49px;
    margin-top: 0;
  }
}
@media screen and (max-width: 680px) {
  .front-page-header h1 {
    font-size: 2.1rem;
  }
}
.front-page-header h1 span {
  display: block;
  padding-left: 130px;
}
@media screen and (max-width: 680px) {
  .front-page-header h1 span {
    padding-left: 50px;
  }
}
.front-page-header .front-page-header-link {
  position: absolute;
  top: 40px;
  right: 0;
  text-decoration: none;
  color: #000;
  font-size: 1.7rem;
  line-height: 2.2rem;
  cursor: pointer;
}
.front-page-header .front-page-header-link span {
  color: #3c8aa0;
}
.front-page-header .front-page-header-link:hover {
  color: #3c8aa0;
}
@media screen and (max-width: 980px) {
  .front-page-header .front-page-header-link {
    top: 0;
  }
}
@media screen and (max-width: 680px) {
  .front-page-header .front-page-header-link {
    bottom: -80px;
    top: auto;
    left: 0px;
    font-size: 1.4rem;
  }
}
.front-page-special .title {
  text-transform: none !important;
}
@media screen and (max-width: 980px) {
  .front-page-special .flex {
    flex-direction: column-reverse;
  }
  .front-page-special img {
    max-width: 100%;
  }
  .front-page-special .front-page-txt-content {
    margin-bottom: 0;
  }
}
.front-page-download .container {
  display: flex;
  border-radius: 64px;
  background: #f7f7f7;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 125px;
  padding: 0;
  width: 90%;
}
@media screen and (max-width: 980px) {
  .front-page-download .container {
    width: calc(100% - 30px);
    flex-direction: column;
    border-radius: 14px;
    margin-top: 60px;
    margin-bottom: 0;
  }
}
.front-page-download__text {
  text-align: left;
  padding: 100px 50px 85px 0px;
}
@media screen and (max-width: 980px) {
  .front-page-download__text {
    padding: 0px 30px 41px;
  }
}
.front-page-download__img {
  width: 40%;
  position: relative;
  margin-right: auto;
}
@media screen and (max-width: 980px) {
  .front-page-download__img {
    width: 100%;
    margin-top: -120px;
  }
}
.front-page-download__img img {
  position: absolute;
  top: -11%;
  left: -35%;
  max-width: 200%;
}
@media screen and (max-width: 980px) {
  .front-page-download__img img {
    position: static;
    max-width: 100%;
  }
}
.front-page-download .btn {
  margin-top: 41px;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.2rem;
  padding: 10px 49px;
  display: inline-block;
}
@media screen and (max-width: 680px) {
  .front-page-download .btn {
    padding: 10px 60px;
    font-size: 1.4rem;
  }
}
.front-page-download h2 {
  text-transform: none;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 15px;
}
@media screen and (max-width: 680px) {
  .front-page-download h2 {
    font-size: 2.1rem;
    max-width: 200px;
  }
}
.front-page-download p {
  margin-bottom: 0 !important;
}
.front-page-txt-img, .front-page-txt-content {
  width: 50%;
}
@media screen and (max-width: 980px) {
  .front-page-txt-img, .front-page-txt-content {
    width: 100%;
    padding: 0 !important;
  }
}
.front-page-txt-img {
  padding-left: 26px;
}
@media screen and (max-width: 680px) {
  .front-page-txt-img {
    margin-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    height: 240px;
    object-fit: cover;
  }
}
.front-page-txt-img img {
  border-radius: 64px;
}
@media screen and (max-width: 980px) {
  .front-page-txt-img img {
    width: 100%;
  }
}
@media screen and (max-width: 680px) {
  .front-page-txt-img img {
    border-radius: 0px;
    width: 100%;
    max-width: 100%;
    height: 240px;
    object-fit: cover;
  }
}
@media screen and (min-height: 1200px) {
  .front-page-txt-img img {
    max-height: 60vh;
  }
}
.front-page-txt-content {
  padding-right: 47px;
}
.front-page-txt-content .btn {
  margin-top: 20px;
}
.front-page-txt-content .subtitle {
  color: #3c8aa0;
  font-size: 2rem;
  margin-bottom: 30px;
}
@media screen and (max-width: 680px) {
  .front-page-txt-content .subtitle {
    margin-bottom: 22px;
    font-size: 1.4rem;
  }
}
.front-page-txt-content .title {
  font-family: "GetVoIP Grotesque";
  font-size: 3rem;
  margin-bottom: 15px;
  text-transform: uppercase;
}
@media screen and (max-width: 680px) {
  .front-page-txt-content .title {
    font-size: 2.1rem;
    margin-bottom: 22px;
  }
}
.front-page-propose .flex {
  margin-bottom: 50px;
}
@media screen and (max-width: 680px) {
  .front-page-propose .flex {
    margin-bottom: unset;
  }
}
.front-page-propose .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 51px;
  grid-row-gap: 50px;
}
@media screen and (max-width: 680px) {
  .front-page-propose .grid {
    display: block;
  }
}
.front-page-propose .grid .col {
  margin-bottom: 30px;
}
.front-page-propose-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.7rem;
  padding-top: 19px;
  margin-bottom: 22px;
  margin-top: 25px;
  border-top: 1px solid #9e9e9e;
}
@media screen and (max-width: 680px) {
  .front-page-propose-header {
    margin-top: 12px;
    font-size: 1.6rem;
  }
}
.front-page-propose-header a {
  text-decoration: none;
  color: #000;
}
.front-page-propose-header a:hover {
  color: #3c8aa0;
}
.front-page-propose-header span {
  color: #3c8aa0;
}
@media screen and (max-width: 680px) {
  .front-page-propose-header span {
    font-size: 1.4rem;
  }
}
.front-page-propose .item {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 680px) {
  .front-page-propose .item {
    border-top: none;
  }
  .front-page-propose .item + .front-page-propose-header {
    border-top: 1px solid #9e9e9e;
  }
}
.front-page-propose .item a {
  color: #000;
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 1.1;
  display: inline-block;
  width: 140px;
}
@media screen and (max-width: 680px) {
  .front-page-propose .item a {
    font-size: 1.4rem;
    width: 115px;
  }
}
.front-page-propose .item a:hover {
  color: #3c8aa0;
}
.front-page-propose .item .price {
  margin-left: 36px;
  font-size: 1.3rem;
}
@media screen and (max-width: 680px) {
  .front-page-propose .item .price {
    font-size: 1.2rem;
  }
}
.front-page-propose img {
  width: 100%;
  border-radius: 14px;
  margin-bottom: 25px;
  height: 188px;
  object-fit: cover;
}
@media screen and (max-width: 680px) {
  .front-page-propose img {
    margin-bottom: 0;
  }
}
.front-page-img {
  margin-top: 34px;
  position: relative;
  margin-bottom: 41px;
}
.front-page-img img {
  max-width: 100%;
}
@media screen and (max-width: 980px) {
  .front-page-img img {
    width: 100%;
    min-height: 210px;
    object-fit: cover;
    border-radius: 0;
    max-height: 70vh;
  }
}
.front-page-img .hint {
  position: absolute;
  top: 26px;
  right: 32px;
  background: #ffffff;
  border-radius: 42px;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.2rem;
  padding: 10px 49px;
  text-decoration: none;
  color: #000;
}
.front-page-img .hint span {
  color: #3c8aa0;
}

.page_main .section-feedback {
  margin-top: 75px;
}
@media screen and (max-width: 680px) {
  .page_main .section-feedback {
    margin-top: 0;
  }
}
.page_main .page-section {
  margin-bottom: 80px;
}
@media screen and (max-width: 680px) {
  .page_main .page-section {
    margin-bottom: 68px;
  }
}
.page_main .section-chess {
  margin-bottom: 60px;
}
.page_main .section-chess .txt-content .title {
  font-size: 3rem;
  margin-bottom: 32px;
}
@media screen and (max-width: 680px) {
  .page_main .section-chess .txt-content .title {
    margin-bottom: 22px;
    font-size: 2.1rem;
  }
}
.page_main .section-chess .fdr .front-page-txt-img {
  margin-right: 25px;
  padding-right: 25px;
}
@media screen and (max-width: 680px) {
  .page_main .section-chess .fdr .front-page-txt-img {
    margin-right: -20px;
  }
}
.page_main .page__filter-inner-row {
  margin-bottom: 30px;
}
@media screen and (max-width: 680px) {
  .page_main .page__filter-inner-row {
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 680px) {
  .page_main .page__filter-type {
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 680px) {
  .page_main .product-items {
    margin-bottom: 68px;
  }
}
@media screen and (max-width: 980px) {
  .page_main .section-chess .flex {
    flex-direction: column-reverse;
  }
  .page_main .section-chess .flex .front-page-txt-img {
    margin-bottom: 41px;
  }
  .page_main .mobile .page__filter-type {
    margin-bottom: 41px;
  }
  .page_main .page-section.page-section--about {
    margin-bottom: 41px;
  }
  .page_main .page-section.page-section--about .title {
    margin-bottom: 10px;
  }
  .page_main .front-page-download.front-page-download {
    margin-top: 160px;
    margin-bottom: 68px;
  }
  .page_main .front-page-special .front-page-txt-img {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 680px) {
  .page_main .product-slider {
    margin-top: 0;
  }
}

@media screen and (max-width: 680px) {
  .download_presentation {
    margin-top: 40px;
  }
}
.pdr0 {
  padding-right: 0 !important;
}

._border--no {
  border: none !important;
}

.text-size-140 {
  font-size: 1.4rem !important;
}

._svg150 svg {
  height: 1.5em;
  width: 1.5em;
  flex-shrink: 0;
  max-width: none;
}

.text-color-black {
  color: #000;
  fill: #000;
  stroke: #000;
}

.ml--large {
  margin-left: 65px !important;
}

.form__action-dropdown {
  box-shadow: 0 5px 13px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding-top: 20px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 7px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1055;
}
.form__action-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
.form__action-dropdown ul li a {
  font-size: 11px;
  color: #000;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.form__action-dropdown ul li a:hover {
  color: #3c8aa0;
}
.form__action-dropdown ul li:not(:last-child) {
  margin-bottom: 13px;
}

.form__action-dropdown-show-all a {
  font-size: 11px;
  color: #000;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 840px) {
  .form__action-dropdown {
    width: 360px;
  }
}
.page-about .section-chess {
  margin-bottom: 0;
}
@media screen and (max-width: 980px) {
  .page-about .section-chess {
    margin-bottom: 0;
  }
}
.page-about-txt-img, .page-about-txt-content {
  width: 50%;
}
@media screen and (max-width: 980px) {
  .page-about-txt-img, .page-about-txt-content {
    width: 100%;
    padding: 0 !important;
  }
}
.page-about-txt-img {
  padding-left: 26px;
}
@media screen and (max-width: 680px) {
  .page-about-txt-img {
    margin-bottom: 0;
    margin-top: 41px;
    height: 240px;
    object-fit: cover;
  }
}
@media screen and (max-width: 945px) and (min-width: 680px) {
  .page-about-txt-img {
    margin-top: 22px;
  }
}
.page-about-txt-img img {
  border-radius: 64px;
}
@media screen and (max-width: 980px) {
  .page-about-txt-img img {
    width: 100%;
    border-radius: 36px;
  }
}
@media screen and (max-width: 945px) and (min-width: 680px) {
  .page-about-txt-img img {
    max-height: 70vh;
  }
}
@media screen and (max-width: 680px) {
  .page-about-txt-img img {
    width: 100%;
    max-width: 100%;
    height: 240px;
    object-fit: cover;
  }
}
.page-about .fdr .page-about-txt-img {
  padding-right: 26px;
}
.page-about .section-feedback {
  margin-top: 75px;
}
@media screen and (max-width: 680px) {
  .page-about .section-feedback {
    margin-top: 68px;
  }
}

.page-product-content__price-wrapper .price {
  display: block;
  font-family: "GetVoIP Grotesque";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.2rem;
  margin-top: 10px;
  margin-bottom: 7px;
}
.page-product-content__price-wrapper .desc {
  font-size: 1.5rem;
}
@media screen and (max-width: 680px) {
  .page-product-content__price-wrapper .desc {
    font-size: 1.4rem;
  }
}
.page-product-content__price-wrapper .row {
  display: flex;
  margin-bottom: 35px;
  justify-content: space-between;
}
.page-product-content__price-wrapper .row .currency {
  font-size: 1.4rem;
}
.page-product-content__price-wrapper .row .currency span {
  display: inline-block;
  margin: 0 4px;
}
.page-product-content__price-wrapper .row .currency a {
  display: inline-block;
  margin: 0 4px;
  text-decoration: none;
}
.page-product-content__price-wrapper .row .desc span {
  color: #3c8aa0;
}
.page-product-content__row {
  margin-top: 45px;
  margin-bottom: 36px;
  justify-content: space-between;
}
@media screen and (max-width: 680px) {
  .page-product-content__row {
    margin-top: 36px;
  }
}
.page-product-content__floor, .page-product-content__square {
  margin-right: 0px;
}
@media screen and (max-width: 680px) {
  .page-product-content__floor, .page-product-content__square {
    margin-right: 67px;
  }
}
.page-product-content__floor span, .page-product-content__square span {
  display: block;
  font-family: "GetVoIP Grotesque";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.2rem;
  margin-top: 10px;
}
.page-product-content__actions {
  margin-top: 50px;
}
@media screen and (max-width: 680px) {
  .page-product-content__actions {
    margin-top: 41px;
    margin-bottom: 0px;
  }
}
.page-product-content__actions .btn {
  margin-bottom: 22px;
  font-size: 1.4rem;
}
@media screen and (max-width: 680px) {
  .page-product-content__actions .btn {
    display: block;
    text-align: center;
  }
  .page-product-content__actions .btn:last-child {
    margin-bottom: 0;
  }
}
.page-product-content__actions .btn:hover {
  color: #3c8aa0;
  border-color: #3c8aa0;
}
.page-product-content__title {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.4;
  margin-bottom: 36px;
}
@media screen and (max-width: 680px) {
  .page-product-content__title {
    margin-bottom: 41px;
  }
}
.page-product-content__details {
  width: 50%;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-right: 20px;
}
@media screen and (max-width: 680px) {
  .page-product-content__details {
    width: 100%;
    margin-bottom: 22px;
  }
}
.page-product-content__details-item {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  font-size: 1.6rem;
  font-weight: 500;
}
@media screen and (max-width: 680px) {
  .page-product-content__details-item {
    font-size: 1.1rem;
    margin-bottom: 22px;
  }
}
.page-product-content__details-item:last-child {
  width: 100%;
  padding-right: 30px;
}
.page-product-content__details-item-icon {
  width: 68px;
  height: 68px;
  background: #f7f7f7;
  border-radius: 14px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media screen and (max-width: 680px) {
  .page-product-content__details-item-icon {
    margin-right: 11px;
    width: 56px;
    height: 56px;
  }
}
.page-product .txt-content h4.title {
  font-family: "GetVoIP Grotesque";
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 40px;
}
@media screen and (max-width: 680px) {
  .page-product .txt-content h4.title {
    font-size: 2.1rem;
    margin-bottom: 22px;
    text-transform: none;
  }
}
.page-product .txt-content .btn {
  margin-top: 30px;
}
@media screen and (max-width: 980px) {
  .page-product .section-chess {
    margin-bottom: 68px;
  }
}
.page-product-txt-img, .page-product-txt-content {
  width: 50%;
}
@media screen and (max-width: 980px) {
  .page-product-txt-img, .page-product-txt-content {
    width: 100%;
    padding: 0 !important;
  }
  .page-product-txt-img .btn, .page-product-txt-content .btn {
    margin-top: 21px;
  }
}
.page-product-txt-img {
  padding-left: 46px;
}
@media screen and (max-width: 680px) {
  .page-product-txt-img {
    margin-bottom: 41px;
    margin-top: 41px;
    height: 240px;
    object-fit: cover;
  }
}
.page-product-txt-img img {
  border-radius: 64px;
}
@media screen and (max-width: 980px) {
  .page-product-txt-img img {
    width: 100%;
    border-radius: 36px;
  }
}
@media screen and (max-width: 680px) {
  .page-product-txt-img img {
    width: 100%;
    max-width: 100%;
    height: 240px;
    object-fit: cover;
  }
}
@media screen and (max-width: 980px) {
  .page-product .section-chess .flex .front-page-txt-img {
    margin-bottom: 41px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .page-product .section-chess .flex .front-page-txt-img img {
    border-radius: 36px;
  }
}
.page-product .page-breadcrumbs {
  margin: 35px 0 38px;
}
.page-product .section-feedback {
  margin-top: 66px;
}
@media screen and (max-width: 680px) {
  .page-product .section-feedback {
    margin-top: unset;
  }
}
.page-product .page-category {
  margin-bottom: 68px;
}

.paging {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 680px) {
  .paging {
    margin-bottom: 46px;
  }
}

.pages {
  margin-left: 40px;
  font-size: 12px;
}

@media screen and (max-width: 680px) {
  .product-slider {
    margin-top: 20px;
  }
}
.product-slider .slick-slide {
  margin: 0 20px;
}
@media screen and (max-width: 980px) {
  .product-slider .slick-slide {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 680px) {
  .product-slider .slick-slide {
    margin: 0 0 40px;
  }
}
.product-slider .slick-list {
  margin: 0 -17px;
}
@media screen and (max-width: 680px) {
  .product-slider .slick-list {
    margin: 0;
  }
}
.product-slider .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: 0;
  background: none;
  border: none;
  font-size: 0;
  width: 24px;
  height: 38px;
  cursor: pointer;
  transform: translateY(-50%);
  background: url(../img/light/arrow-slick.svg) no-repeat;
  background-size: contain;
}
@media screen and (max-width: 680px) {
  .product-slider .slick-arrow {
    display: none !important;
  }
}
.product-slider .slick-arrow:hover {
  background: url(../img/light/arrow-slick-hover.svg) no-repeat;
  background-size: contain;
}
.product-slider .slick-arrow.slick-disabled {
  opacity: 0.4;
}
.product-slider .slick-arrow.slick-prev {
  left: -50px;
}
.product-slider .slick-arrow.slick-next {
  right: -70px;
  transform-origin: left;
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 1130px) {
  .product-slider .slick-arrow.slick-next {
    right: -40px;
  }
  .product-slider .slick-arrow.slick-prev {
    left: -20px;
  }
}

.page-category__content-header {
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
  margin-bottom: 57px;
  align-items: center;
}
@media screen and (max-width: 680px) {
  .page-category__content-header {
    margin-bottom: 41px;
    margin-top: 41px;
  }
}

.page-category__content-filters {
  display: flex;
  align-content: center;
  justify-content: center;
}
@media screen and (max-width: 680px) {
  .page-category__content-filters {
    flex-direction: column;
    font-size: 1.3rem;
    width: 100%;
  }
}

.page-category__content-filter-title {
  font-size: 1.4rem;
}
@media screen and (max-width: 680px) {
  .page-category__content-filter-title {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
}

.page-category__content-filter-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  margin-left: 25px;
}
@media screen and (max-width: 680px) {
  .page-category__content-filter-list {
    margin-left: 0;
    display: flex;
    justify-content: start;
    font-size: 1.1rem;
  }
}
.page-category__content-filter-list li {
  display: inline-block;
}
.page-category__content-filter-list li:not(:last-child) {
  margin-right: 25px;
}
@media screen and (max-width: 680px) {
  .page-category__content-filter-list li:not(:last-child) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 680px) {
  .page-category__content-filter-list li {
    margin-right: 0px;
    padding: 5px 10px 5px 0;
  }
}
.page-category__content-filter-list li a {
  text-decoration: none;
  color: #000;
}
.page-category__content-filter-list li.active a {
  color: #3c8aa0;
}
.page-category__content-filter-list li.active:after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  font-size: 11px;
}
.page-category__content-filter-list li.up:after {
  content: "↓";
}
.page-category__content-filter-list li.down:after {
  content: "↑";
}

.page-category__content-filter--buttons {
  display: flex;
  align-items: center;
  width: 48px;
  justify-content: space-between;
}
.page-category__content-filter--buttons > a, .page-category__content-filter--buttons > div {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: block;
  font-size: 0;
}
.page-category__content-filter--buttons-tab {
  background: url(../img/light/icon-filter-tab.svg) 50% 50% no-repeat;
}
.page-category__content-filter--buttons-tab.active {
  background: url(../img/light/icon-filter-tab-h.svg) 50% 50% no-repeat;
}
.page-category__content-filter--buttons-list {
  background: url(../img/light/icon-filter-list.svg) 50% 50% no-repeat;
}
.page-category__content-filter--buttons-list.active {
  background: url(../img/light/icon-filter-list-h.svg) 50% 50% no-repeat;
}

.product-items .page__filter-inner-row {
  margin-bottom: 60px;
}
@media screen and (max-width: 680px) {
  .product-items .page__filter-inner-row {
    margin-bottom: 41px;
  }
}
.product-items .slick-track {
  margin-left: 0;
}

.product-item__inner {
  border-radius: 14px;
  box-sizing: border-box;
  overflow: hidden;
  width: calc(33.3333333333% - 32px);
  position: relative;
}
@media screen and (max-width: 680px) {
  .product-item__inner {
    margin-bottom: 41px;
    width: unset;
  }
}
.product-item__inner > a {
  color: #000;
  text-decoration: none;
  display: block;
  height: 100%;
}
.product-item__inner:hover .product-item__content {
  border-color: #3c8aa0;
}
.product-item__content {
  padding: 40px 20px 30px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border: 1px solid #9e9e9e;
  border-top: 0;
}
.product-item__title {
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #000;
  text-decoration: none;
}
@media screen and (max-width: 980px) {
  .product-item__title {
    font-size: 1.5rem;
    line-height: 1;
  }
}
.product-item__gallery {
  width: 100%;
  margin-bottom: -14px;
}
.product-item__gallery img {
  width: 100%;
  height: 207px;
  object-fit: cover;
  border-radius: 14px;
}
.product-item__image {
  margin-bottom: -14px;
}
.product-item__image img {
  width: 100%;
  border-radius: 14px;
}
.product-item_info {
  margin-top: 13px;
}
.product-item__tower {
  font-size: 1.4rem;
  line-height: 1.4;
  text-decoration: none;
  display: block;
  margin-top: 5px;
  color: #3c8aa0 !important;
}
@media screen and (max-width: 680px) {
  .product-item__tower {
    font-size: 1.2rem;
  }
}
.product-item__info-item_price {
  font-family: "GetVoIP Grotesque";
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 1;
}
@media screen and (max-width: 680px) {
  .product-item__info-item_price {
    margin-top: 20px;
    font-size: 1.9rem;
  }
}
.product-item__info-item_price .per-month {
  font-size: 1.4rem;
  line-height: 1.4;
  text-decoration: none;
  display: block;
  margin-top: 5px;
  color: #3c8aa0;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
}
.product-item__info-item_price-m {
  font-size: 1.7rem;
  line-height: 1;
  margin-top: 10px;
}
.product-item__info-item_price-m span {
  font-size: 1.4rem;
  color: #9e9e9e;
}
.product-item__tags {
  list-style: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 680px) {
  .product-item__tags {
    justify-content: start;
    justify-content: space-between;
  }
}
.product-item__tags-item {
  display: flex;
  align-items: center;
}
.product-item__tags-item:not(:last-child) {
  margin-right: 11px;
}
.product-item__tags-item-text {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
@media screen and (max-width: 680px) {
  .product-item__tags-item svg {
    height: 30px;
  }
}
.product-item__data {
  height: 80px;
}
@media screen and (max-width: 680px) {
  .product-item__data {
    height: auto;
  }
}
.product-item__footer {
  margin-top: 30px;
}

.product_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product_list .product-item__inner {
  margin-bottom: 60px;
}
@media screen and (max-width: 680px) {
  .product_list .product-item__inner {
    margin-bottom: 40px;
    width: 100%;
  }
  .product_list .product-item__inner:last-child {
    margin-bottom: 0px;
  }
}
.product_list .product-item__inner .btn {
  display: none;
}

.product_list-rows {
  flex-direction: column;
}
.product_list-rows .product-item__inner {
  width: 100%;
  margin-bottom: 57px;
  display: flex;
  padding: 0;
  border-left: 0;
  position: relative;
  overflow: visible !important;
}
.product_list-rows .product-item__inner > a {
  height: 100%;
  position: relative;
}
.product_list-rows .product-item__inner .btn {
  display: inline-block;
  text-align: center;
  color: #fff;
}
.product_list-rows .product-item__inner .product-item__action {
  position: absolute;
  top: 22px;
  right: 24px;
  text-align: right;
}
.product_list-rows .product-item__inner .product-item__action .add-fav {
  font-size: 0;
  background: url(../img/light/icon-star.svg) no-repeat;
  display: block;
  width: 24px;
  height: 23px;
  margin-left: auto;
  margin-bottom: 16px;
  cursor: pointer;
}
.product_list-rows .product-item__inner .product-item__action .add-fav:hover {
  background: url(../img/light/icon-star-h.svg) no-repeat;
}
.product_list-rows .product-item__data {
  height: auto;
  margin-bottom: 22px;
}
.product_list-rows .product-item__image {
  width: 50%;
  margin-bottom: 0;
  margin-right: -14px;
}
.product_list-rows .product-item__image img {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  object-fit: cover;
  position: relative;
}
.product_list-rows .product-item__content {
  padding: 25px 24px 25px 50px;
  width: calc(55% + 14px);
  border-top: 1px solid #9e9e9e;
  border-left: 0;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 0;
}
.product_list-rows .product-item__title {
  font-size: 1.9rem;
}
.product_list-rows .product-item__info-item_price {
  font-size: 2.4rem;
  margin-bottom: 0;
}
.product_list-rows .product-item__tags {
  flex-wrap: wrap;
  margin-bottom: 16px;
  width: 90%;
}
.product_list-rows .product-item__tags li {
  width: 45%;
  margin: 9px 0;
  font-size: 1.6rem;
}
.product_list-rows .product-item__tags li .icon {
  display: inline-block;
  display: flex;
  background: #f7f7f7;
  border-radius: 14px;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}
.product_list-rows .product-item__tags li .product-item__tags-item-text {
  margin-left: 0;
}
.product_list-rows + .btn-more {
  margin-bottom: 85px;
}

.product_list-tab::after {
  content: "";
  width: calc(33.3% - 23.5px);
}
@media screen and (max-width: 680px) {
  .product_list-tab::after {
    display: none;
  }
}
.product_list-tab .product-item__inner {
  width: calc(33.3% - 23.5px);
}
@media screen and (max-width: 680px) {
  .product_list-tab .product-item__inner {
    width: 100%;
  }
}
.product_list-tab .product-item__info-item_price-m,
.product_list-tab .product-item__action {
  display: none;
}
.product_list-tab .product-item__image img {
  width: 100%;
  border-radius: 14px;
}
@media screen and (max-width: 680px) {
  .product_list-tab {
    margin-bottom: 41px;
  }
}

.page-product-content__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 680px) {
  .page-product-content__row {
    flex-direction: column;
  }
}

.page-product-content__gallery {
  width: 70%;
  position: relative;
}
@media screen and (max-width: 980px) {
  .page-product-content__gallery {
    width: 60%;
  }
}
@media screen and (max-width: 680px) {
  .page-product-content__gallery {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 36px;
  }
}

.page-product-content__body {
  padding-left: 60px;
}
@media screen and (max-width: 680px) {
  .page-product-content__body {
    padding-left: 0;
    width: 100%;
  }
  .page-product-content__body .page-product-content__row {
    flex-direction: row;
    align-items: baseline;
    justify-content: start !important;
  }
}
.page-product-content__body .page-product-content__body-header {
  position: relative;
}
.page-product-content__body .page-product-content__body-header a {
  display: block;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-top: 8px;
  text-decoration: none;
}
@media screen and (max-width: 680px) {
  .page-product-content__body .page-product-content__body-header a {
    font-size: 1.2rem;
    margin-top: 0;
  }
}
.page-product-content__body .page-product-content__body-header .id {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.page-product-content__body .page-product-content__body-header .add-fav {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
  background: url(../img/light/icon-star.svg) no-repeat;
  background-size: contain;
  display: block;
  width: 24px;
  height: 23px;
  margin-left: auto;
  margin-bottom: 10px;
  cursor: pointer;
}
@media screen and (max-width: 680px) {
  .page-product-content__body .page-product-content__body-header .add-fav {
    width: 19.05px;
    height: 18px;
  }
}
.page-product-content__body .page-product-content__body-header .add-fav:hover {
  background: url(../img/light/icon-star-h.svg) no-repeat;
  background-size: contain;
}
.page-product-content__body .page-product-content__body-header .add-fav.active {
  background: url(../img/light/icon-star-h.svg) no-repeat;
  background-size: contain;
}

.section-related .product_list {
  flex-wrap: nowrap;
}
@media screen and (max-width: 680px) {
  .section-related .product_list {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}
.section-related .product-item__inner:not(:last-child) {
  margin-right: 47px;
}
@media screen and (max-width: 680px) {
  .section-related .product-item__inner:not(:last-child) {
    margin-right: 0;
  }
}
.section-related h2 {
  margin-bottom: 48px;
}
@media screen and (max-width: 680px) {
  .section-related h2 {
    margin-bottom: 41px;
  }
}
.section-related .product-item__inner {
  margin-bottom: 46px;
}
@media screen and (max-width: 680px) {
  .section-related .product-item__inner {
    margin-bottom: 41px;
  }
}
.section-related .btn-more + h2 {
  margin-top: 87px;
}
@media screen and (max-width: 680px) {
  .section-related .btn-more + h2 {
    margin-top: 64px;
  }
}

.custom-select {
  position: relative;
  z-index: 1051;
}
.custom-select .page__filter-label {
  transform: translateY(-1px);
}

.custom-select__title {
  display: block;
  font-size: 1.4rem;
}
@media screen and (max-width: 680px) {
  .custom-select__title {
    font-size: 1.2rem;
  }
}

.select2-results {
  font-size: 1.7rem;
  background: #f7f7f7;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
@media screen and (max-width: 680px) {
  .select2-results {
    font-size: 1.5rem;
  }
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background: none;
  color: unset;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable:hover {
  color: #3c8aa0;
}

.select2-results__option.select2-results__option--selectable.select2-results__option--selected {
  display: none;
}

.select2-container--default .select2-results__option--selected {
  background: none;
}

.select2-dropdown {
  border: none;
  background: none;
  margin-left: -15px;
  width: calc(100% + 45px) !important;
}

.select2-results__option {
  padding: 6px 15px;
}

.custom-select .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  line-height: 2.2rem;
  font-size: 1.7rem;
}
@media screen and (max-width: 680px) {
  .custom-select .select2-selection--single .select2-selection__rendered {
    font-size: 1.5rem;
  }
}

.custom-select .select2-selection--single {
  border-radius: 0;
  border: 0;
  height: auto;
  padding: 0;
  outline: none;
  background: transparent;
}

.custom-select-label {
  display: block;
  width: 100%;
}

.select2 {
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: -25px;
  width: 20px;
  height: 20px;
  background: url(../img/light/arrow-down.svg) 100% 50% no-repeat;
  border: none;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  background: url(../img/light/arrow-up.svg) 100% 50% no-repeat;
}

.custom-select_border-white .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: 2px solid #fff;
}

.custom-select_border-white .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff;
}

.custom-select_border-black .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: 2px solid #000;
}

.custom-select_border-black .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000;
}

.custom-select_black .select2-container--default .select2-selection--single {
  background-color: transparent;
}

.custom-select_black .page-category__label-title {
  margin-left: 0;
  margin-right: 0;
  text-transform: none;
}

.custom-select__dropdown-title-link {
  display: block;
  padding-left: 15px;
  padding-right: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #444;
  line-height: 38px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.custom-select__dropdown-title-link.active .custom-select__dropdown-title-icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.custom-select__dropdown-title-icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 21px;
  height: 12px;
}

.custom-select__dropdown {
  background-color: #fff;
  width: 100%;
  width: 230px;
  max-height: 210px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  right: -18px;
  margin-top: 10px;
  display: none;
}

.custom-select__dropdown.active {
  display: block;
}

.custom-select__dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.custom-select__dropdown ul li a {
  text-transform: uppercase;
  padding: 12px;
  padding-right: 40px;
  font-size: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000;
  position: relative;
}

.custom-select__dropdown ul li a:hover {
  background-color: #f5f5f5;
  color: #000;
}

.custom-select__dropdown ul li a:hover {
  color: #3c8aa0;
}

.custom-select__dropdown ul li a.active::before {
  content: "";
  display: block;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-image: url(../img/svg-icons/close.svg);
  background-size: 12px 12px;
  background-position: center center;
  background-repeat: no-repeat;
}

.page-category__label-content .flex {
  flex-direction: row;
}

.custom-range_to,
.custom-range_from {
  width: 50%;
  white-space: nowrap;
}

.custom-range_to {
  text-align: right;
}

@media screen and (max-width: 680px) {
  .section-feedback {
    margin-top: 68px;
  }
}
.section-feedback-form {
  width: 65%;
  background: #f7f7f7;
  border-radius: 14px;
  padding: 42px 28px;
  margin-right: 50px;
}
@media screen and (max-width: 680px) {
  .section-feedback-form {
    width: calc(100% + 40px);
    border-radius: 0;
    padding: 40px 20px;
    margin-right: -20px;
    margin-left: -20px;
    margin-top: 0;
  }
}
.section-feedback-form__row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.section-feedback-form__field {
  position: relative;
  width: 48%;
}
@media screen and (max-width: 680px) {
  .section-feedback-form__field {
    width: 100%;
    margin-bottom: 10px;
  }
}
.section-feedback-form__field input:focus ~ span {
  display: none;
}
.section-feedback-form__field span {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
@media screen and (max-width: 680px) {
  .section-feedback-form__field span {
    font-size: 1.3rem;
  }
}
.section-feedback-form__radio {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 980px) {
  .section-feedback-form__radio {
    justify-content: start;
  }
}
.section-feedback-form__radio input {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}
.section-feedback-form__radio label {
  border: 1px solid #000;
  border-radius: 42px;
  padding: 10px 30px;
  font-size: 1.3rem;
  line-height: 1;
  height: auto;
  margin-bottom: 10px;
  cursor: pointer;
}
@media screen and (max-width: 980px) {
  .section-feedback-form__radio label {
    margin-right: 20px;
  }
}
.section-feedback-form__radio input:checked + label {
  border: 1px solid #3c8aa0;
  color: #3c8aa0;
}
.section-feedback-form__radio-label {
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 25px;
  margin-top: 20px;
  display: block;
  position: static;
}
@media screen and (max-width: 680px) {
  .section-feedback-form__radio-label {
    font-size: 1.4rem;
    margin-bottom: 14px;
  }
}
.section-feedback-form__checkbox {
  margin-bottom: 37px;
}
.section-feedback-info {
  font-weight: 400;
}
@media screen and (max-width: 680px) {
  .section-feedback-info {
    display: none;
  }
}
.section-feedback-info .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.section-feedback-info .icon:hover svg path {
  fill: #3c8aa0 !important;
}
.section-feedback-info .icon.fav:hover svg path {
  fill: none !important;
  stroke: #3c8aa0;
}
.section-feedback-email {
  display: block;
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 5px;
  color: #3c8aa0;
  text-decoration: none;
}
.section-feedback-phone {
  display: block;
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 20px;
  color: #000;
  text-decoration: none;
}
.section-feedback p {
  margin-bottom: 15px;
}
.section-feedback h3 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.4;
  margin-bottom: 0px;
  font-family: "SF Pro Display", sans-serif;
}
@media screen and (max-width: 680px) {
  .section-feedback h3 {
    font-size: 2.1rem;
    line-height: 2.4rem;
    margin-bottom: 12px;
  }
}
.section-feedback .flex {
  margin-top: 49px;
}
@media screen and (max-width: 680px) {
  .section-feedback .flex {
    margin-top: 20px;
  }
}
.section-feedback .input_text {
  background: #ffffff;
  border-radius: 42px;
  border: none;
  padding: 10px 20px;
  width: 100%;
}

.page-breadcrumbs {
  margin: 35px 0 38px;
}
.page-breadcrumbs__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-right: 20px;
}
.page-breadcrumbs__item a {
  text-decoration: none;
  color: #000;
}
.page-breadcrumbs__item span {
  color: #3c8aa0;
}
.page-breadcrumbs__item:not(:last-child):after {
  position: absolute;
  top: 0;
  right: 7px;
  content: "/";
  font-size: 1.4rem;
}

.page-breadcrumbs + .page-return__item {
  margin-top: -17px;
}

.form__checkbox {
  margin-bottom: 37px;
}
@media screen and (max-width: 680px) {
  .form__checkbox {
    height: 20px;
    margin-bottom: 18px;
  }
}
.form__checkbox input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.form__checkbox label {
  position: relative;
  padding-left: 35px;
  font-size: 1.2rem;
}
@media screen and (max-width: 680px) {
  .form__checkbox label {
    font-size: 10px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.form__checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #fff;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 4px;
}
@media screen and (max-width: 680px) {
  .form__checkbox label::before {
    top: 0;
    transform: none;
  }
}
.form__checkbox label::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(../img/icon-check.svg);
  opacity: 0;
  left: 3px;
  top: 0px;
}
@media screen and (max-width: 680px) {
  .form__checkbox label::after {
    top: 3px;
  }
}
.form__checkbox input:checked + label::after {
  opacity: 1;
}

.slider-gallery .slick-current.slick-active {
  outline: none !important;
  margin: 0;
  opacity: 1;
  min-height: 420px;
  object-fit: cover;
  border-radius: 64px;
}
@media screen and (max-width: 980px) {
  .slider-gallery .slick-current.slick-active {
    border-radius: 0;
    min-height: 330px;
    height: 330px;
  }
}
.slider-gallery .slick-current.slick-active img {
  outline: none !important;
  margin: 0;
  opacity: 1;
  min-height: 420px;
  object-fit: cover;
  border-radius: 64px;
}
@media screen and (max-width: 980px) {
  .slider-gallery .slick-current.slick-active img {
    border-radius: 0;
    min-height: 330px;
  }
}

.slider-gallery .slick-slide img {
  height: 100%;
  max-width: 100%;
  margin: auto !important;
  object-fit: cover;
  border-radius: 64px;
}
@media screen and (max-width: 980px) {
  .slider-gallery .slick-slide img {
    border-radius: 0;
    height: 330px;
    width: 100%;
  }
}
.slider-gallery .slick-track {
  height: 500px;
  display: flex !important;
  flex-wrap: nowrap;
  align-items: stretch;
}
.slider-gallery .slick-slide {
  height: auto;
}
.slider-for img {
  width: 100%;
  height: 100%;
}

.slider-nav {
  margin: 26px auto 0px;
}
@media screen and (max-width: 680px) {
  .slider-nav {
    display: none;
  }
}
.slider-nav .slick-arrow {
  display: none !important;
}

.slider-nav .item-slick {
  max-width: 144px;
  height: 96px;
  margin-right: 13px !important;
  margin-left: 0;
  border-radius: 14px !important;
  outline: none !important;
  opacity: 0.3;
  cursor: pointer;
  min-height: auto !important;
}
@media screen and (max-width: 980px) {
  .slider-nav .item-slick {
    max-width: 135px;
  }
}

.slider-nav .item-slick {
  max-width: 100%;
  background-size: cover;
  background-position: center;
}

.slider-nav .slick-current .item-slick {
  opacity: 1;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: 14px;
  z-index: 50;
  width: 48px;
  height: 48px;
  border: none;
  background: #000 url(../img/light/arrow-slick-w.svg) 40% 50% no-repeat;
  transform: translateY(-50%);
  border-radius: 100px;
  background-size: 15px;
  font-size: 0;
  cursor: pointer;
}
@media screen and (max-width: 680px) {
  .slick-arrow {
    width: 32px;
    height: 32px;
    background-size: 10px;
  }
}
.slick-arrow.slick-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.slick-arrow.slick-prev {
  left: 20px;
}
.slick-arrow.slick-next {
  right: -28px;
  transform-origin: left;
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 680px) {
  .slick-arrow.slick-next {
    right: -13px;
  }
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  list-style: none;
}
.slick-dots li {
  display: inline-block;
  margin: 0 5px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  background: none;
  border: none;
}
.slick-dots li.slick-active {
  background: #3c8aa0;
}

@media screen and (max-width: 680px) {
  .page-product-order {
    margin-top: 68px;
  }
}
.page-product-order-row {
  background: #f7f7f7;
  border-radius: 14px;
  padding: 33px 28px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 680px) {
  .page-product-order-row {
    flex-direction: column;
    padding: 41px 20px;
    align-items: flex-start;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.page-product-order-form__row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
  padding-right: 73px;
}
@media screen and (max-width: 680px) {
  .page-product-order-form__row {
    padding-right: 0;
  }
}
.page-product-order-form__field {
  position: relative;
  width: 48%;
}
@media screen and (max-width: 680px) {
  .page-product-order-form__field {
    width: 100%;
  }
  .page-product-order-form__field:not(:last-child) {
    margin-bottom: 10px;
  }
}
.page-product-order-form__field .input_text {
  background: #ffffff;
  border-radius: 42px;
  border: none;
  padding: 10px 20px;
  width: 100%;
}
.page-product-order-form__field input:focus ~ span {
  display: none;
}
.page-product-order-form__field span {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
@media screen and (max-width: 680px) {
  .page-product-order-form__field span {
    font-size: 1.3rem;
  }
}
.page-product-order .subscribe-form__title {
  font-size: 25px;
  margin-bottom: 41px;
  font-weight: bold;
}
@media screen and (max-width: 680px) {
  .page-product-order .subscribe-form__title {
    font-size: 1.9rem;
  }
}
.page-product-order .form__control {
  background: #ffffff;
  border-radius: 42px;
  border: none;
  padding: 9px 20px;
  width: 42%;
}
.page-product-order .form__control + .form__control {
  margin-left: 30px;
}
@media screen and (max-width: 680px) {
  .page-product-order .form__control + .form__control {
    margin-left: 0;
  }
}
@media screen and (max-width: 680px) {
  .page-product-order .form__control {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
.page-product-order-form {
  width: 71%;
}
@media screen and (max-width: 680px) {
  .page-product-order-form {
    width: 100%;
  }
}
.page-product-order-contacts {
  padding: 0;
}
@media screen and (max-width: 680px) {
  .page-product-order-contacts {
    margin-top: 40px;
  }
}
.page-product-order-contacts p {
  font-size: 1.4rem;
}
.page-product-order-contacts-img {
  border-radius: 100px;
  margin-right: 40px;
  width: 120px;
}
.page-product-order-contacts-manager {
  font-size: 2rem;
  line-height: 35px;
}
.page-product-order-contacts-social {
  margin-top: 10px;
}
.page-product-order-contacts-social .icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
@media screen and (max-width: 680px) {
  .page-product-order-contacts-social .icon {
    width: 24px;
    height: 24 px;
  }
}
.page-product-order-contacts-social .icon svg {
  width: 100%;
  height: 100%;
}
.page-product-order-contacts-social .icon:hover svg path {
  fill: #3c8aa0 !important;
}
.page-product-order-contacts-img {
  width: 99px;
  height: 99px;
  margin-bottom: 17px;
  margin-top: 10px;
}
@media screen and (max-width: 680px) {
  .page-product-order-contacts-img {
    margin-top: 0;
  }
}
.page-product-order-contacts-phone {
  color: #000;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
@media screen and (max-width: 680px) {
  .page-product-order-contacts-phone {
    font-size: 1.7rem;
    margin-left: 0;
  }
}
.page-product-order .order-form__checkbox {
  display: block;
  margin-top: 23px;
  margin-bottom: 37px;
  position: relative;
}
@media screen and (max-width: 680px) {
  .page-product-order .order-form__checkbox {
    margin-top: 14px;
    margin-bottom: 18px;
  }
}

.page-product-contacts {
  background: #f7f7f7;
  border-radius: 14px;
  padding: 40px 47px;
}
@media screen and (max-width: 680px) {
  .page-product-contacts {
    padding: 41px 20px;
    margin: 0 -20px;
  }
}
.page-product-contacts-inner {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 680px) {
  .page-product-contacts-inner {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
}
.page-product-contacts-inner .page-product-order-social,
.page-product-contacts-inner .page-product-order-phone {
  margin-left: 70px;
}
@media screen and (max-width: 680px) {
  .page-product-contacts-inner .page-product-order-social,
.page-product-contacts-inner .page-product-order-phone {
    margin-left: 0;
  }
}
.page-product-contacts-img {
  width: 120px;
  height: 120px;
  margin-bottom: 12px;
}
.page-product-contacts-manager {
  font-size: 2rem;
  margin-left: 29px;
}
@media screen and (max-width: 680px) {
  .page-product-contacts-manager {
    font-size: 1.7rem;
    margin-top: 10px;
    margin-left: 0;
  }
}
.page-product-contacts-manager span {
  display: block;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
@media screen and (max-width: 680px) {
  .page-product-contacts-manager span {
    font-size: 1.2rem;
  }
}
.page-product-contacts-title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 35px;
  margin-bottom: 40px;
}
@media screen and (max-width: 680px) {
  .page-product-contacts-title {
    margin-left: 0;
    font-size: 1.9rem;
    margin-bottom: 22px;
  }
}
.page-product-contacts-phone {
  color: #000;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 12px;
  margin-left: 99px;
  display: block;
}
@media screen and (max-width: 680px) {
  .page-product-contacts-phone {
    font-size: 1.7rem;
    margin-left: 0;
  }
}
.page-product-contacts-social {
  margin-top: 10px;
  margin-left: 68px;
}
@media screen and (max-width: 680px) {
  .page-product-contacts-social {
    margin-left: 0;
  }
}
.page-product-contacts-social .icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.page-product-contacts-social .icon svg {
  width: 100%;
  height: 100%;
}
.page-product-contacts-social .icon:hover svg path {
  fill: #3c8aa0 !important;
}

[data-form-ok] {
  display: none;
  margin-bottom: 30px;
}

.page-contact-row__info {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 35px;
}
@media screen and (max-width: 680px) {
  .page-contact-row__info {
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.page-contact-row__col {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media screen and (max-width: 680px) {
  .page-contact-row__col {
    margin-bottom: 30px;
  }
}
.page-contact__address {
  line-height: 1.4;
}
.page-contact__title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 35px;
}
@media screen and (max-width: 680px) {
  .page-contact__title {
    font-size: 1.7rem;
  }
}
.page-contact__social {
  margin-top: auto;
}
@media screen and (max-width: 680px) {
  .page-contact__social {
    margin-top: 24px;
  }
}
.page-contact__social p {
  margin-bottom: 15px;
}
.page-contact__social .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.page-contact__social .icon:hover svg path {
  fill: #3c8aa0 !important;
}
.page-contact__social .icon.fav:hover svg path {
  fill: none !important;
  stroke: #3c8aa0;
}
.page-contact__site {
  display: block;
  font-size: 2rem;
  text-decoration: none;
  margin-bottom: 40px;
}
@media screen and (max-width: 680px) {
  .page-contact__site {
    font-size: 1.7rem;
    margin-bottom: 0px;
  }
}
.page-contact__mail {
  font-size: 2rem;
  text-decoration: none;
}
@media screen and (max-width: 680px) {
  .page-contact__mail {
    font-size: 1.7rem;
  }
}
.page-contact__phone {
  color: #000;
  font-size: 2rem;
  margin-top: 5px;
}
@media screen and (max-width: 680px) {
  .page-contact__phone {
    font-size: 1.7rem;
  }
}
.page-contact__map {
  margin-bottom: 86px;
  height: 427px;
}
@media screen and (max-width: 680px) {
  .page-contact__map {
    margin-bottom: 62px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media screen and (max-width: 680px) {
  .page-contact p {
    max-width: 230px;
  }
}

.screen-contacts__map {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-towers-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-towers__inner {
  flex-grow: 1;
  width: calc(50% - 47px);
  max-width: calc(50% - 25px);
  margin-right: 47px;
}
@media screen and (max-width: 680px) {
  .page-towers__inner {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}
.page-towers__inner:not(:last-child) {
  margin-bottom: 60px;
}
.page-towers__inner-img {
  overflow: hidden;
  border-radius: 14px;
  margin-bottom: 39px;
}
@media screen and (max-width: 680px) {
  .page-towers__inner-img {
    margin-bottom: 22px;
  }
}
.page-towers__inner-img img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 230px;
  object-fit: cover;
}
@media screen and (max-width: 680px) {
  .page-towers__inner-img img {
    max-height: 230px;
    object-fit: cover;
    width: 100%;
  }
}
.page-towers__inner:nth-child(2n) {
  margin-right: 0;
}
.page-towers__title {
  font-weight: 700;
  font-size: 2.1rem;
  margin-bottom: 22px;
  font-family: "GetVoIP Grotesque";
}
.page-towers__proposes {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}
@media screen and (max-width: 680px) {
  .page-towers__proposes {
    margin-bottom: 0px;
  }
}
.page-towers__proposes li {
  flex-grow: 1;
  border-top: 1px solid rgb(158, 158, 158);
  padding-top: 14px;
  font-size: 1.7rem;
  line-height: 2.2rem;
  flex-basis: 50%;
}
@media screen and (max-width: 680px) {
  .page-towers__proposes li {
    font-size: 1.6rem;
    margin-bottom: 32px;
    width: 45%;
    flex-grow: 0;
    flex-basis: unset;
  }
  .page-towers__proposes li span {
    font-size: 1.4rem;
  }
}
.page-towers__proposes li a {
  display: flex;
  justify-content: space-between;
  color: #000;
  text-decoration: none;
  margin-bottom: 7px;
}
.page-towers__proposes li a span {
  color: #3c8aa0;
}
.page-towers__proposes li a:hover {
  color: #3c8aa0;
}
.page-towers__proposes li:not(:first-child) {
  margin-left: 47px;
}
@media screen and (max-width: 680px) {
  .page-towers__proposes li:not(:first-child) {
    margin-left: 0;
  }
}
.page-towers-proposes {
  margin-top: 85px;
  margin-bottom: 45px;
}
@media screen and (max-width: 680px) {
  .page-towers-proposes {
    margin-top: 68px;
    margin-bottom: initial;
  }
}
.page-towers-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 33px;
  margin-top: 25px;
  flex-wrap: wrap;
}
@media screen and (max-width: 680px) {
  .page-towers-header {
    margin-top: 41px;
    margin-bottom: 41px;
  }
}
.page-towers__info {
  justify-content: space-between;
  display: flex;
  width: 50%;
}
@media screen and (max-width: 680px) {
  .page-towers__info {
    width: 100%;
    margin-bottom: 22px;
  }
}
.page-towers__info-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.page-towers__info .value {
  font-family: "GetVoIP Grotesque";
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.2rem;
  margin-top: 13px;
}
.page-towers__img {
  border-radius: 64px;
  margin-bottom: 86px;
  overflow: hidden;
}
@media screen and (max-width: 680px) {
  .page-towers__img {
    border-radius: 0;
    margin: 0 -20px;
  }
}
.page-towers__img img {
  display: block;
  height: 334px;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 680px) {
  .page-towers__img img {
    height: 230px;
  }
}
.page-towers__desc {
  width: 50%;
  font-size: 1.4rem;
  line-height: 28px;
  flex-shrink: 0;
}
@media screen and (max-width: 680px) {
  .page-towers__desc {
    width: 100%;
    line-height: 17px;
  }
}
.page-towers-wrapper-row .page-towers__inner {
  display: flex;
}
@media screen and (max-width: 680px) {
  .page-towers-wrapper-row .page-towers__inner {
    flex-direction: column;
  }
}
.page-towers-wrapper-row .page-towers__inner {
  justify-content: space-between;
  margin-right: 0;
  max-width: 100%;
}
.page-towers-wrapper-row .page-towers__inner-img {
  width: 30%;
  flex-shrink: 0;
  margin-right: 47px;
  margin-bottom: 47px;
}
@media screen and (max-width: 680px) {
  .page-towers-wrapper-row .page-towers__inner-img {
    width: 100%;
    margin: 0;
  }
}
.page-towers-wrapper-row .page-towers__proposes {
  flex-grow: 1;
}
@media screen and (max-width: 680px) {
  .page-towers-wrapper-row .page-towers__proposes {
    flex-direction: column;
  }
}
.page-towers-wrapper-row .page-towers__proposes li {
  width: 100%;
}
@media screen and (max-width: 680px) {
  .page-towers-wrapper-row .page-towers__proposes li:first-child {
    margin-top: 28px;
  }
}
.page-towers-wrapper-row .page-towers__proposes .page-towers__proposes-content {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 680px) {
  .page-towers-wrapper-row .page-towers__proposes .page-towers__proposes-content {
    font-weight: bold;
  }
}
.page-towers-wrapper-row .page-towers__proposes .page-towers__proposes-content span {
  color: #3c8aa0;
}
.page-towers-wrapper-row .page-towers__proposes .page-towers__proposes-price {
  margin-top: 20px;
  font-weight: normal;
  margin-bottom: 28px;
  font-size: 1.3rem;
}
@media screen and (max-width: 680px) {
  .page-towers-wrapper-row .page-towers__proposes .page-towers__proposes-price {
    margin-top: 14px;
    margin-bottom: 22px;
  }
}
.page-towers-wrapper-row .page-towers__proposes .btn {
  display: inline-block;
}
.page-towers .section-chess {
  margin-bottom: 92px;
}
@media screen and (max-width: 680px) {
  .page-towers .section-chess {
    margin-bottom: 68px;
  }
}
.page-towers .product-items {
  margin-top: 77px;
}
@media screen and (max-width: 680px) {
  .page-towers .product-items {
    margin-top: initial;
  }
}
.page-towers .product-slider {
  margin-bottom: 92px;
}
@media screen and (max-width: 680px) {
  .page-towers .product-slider {
    margin-top: 24px;
    margin-bottom: 41px;
  }
}
.page-towers .page-img {
  position: relative;
  margin-top: 16px;
}
.page-towers .page-img .hint {
  position: absolute;
  top: 12px;
  right: 32px;
  background: #ffffff;
  border-radius: 42px;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.2rem;
  padding: 10px 49px;
  text-decoration: none;
  color: #000;
}
.page-towers .page-img .hint span {
  color: #3c8aa0;
}
.page-towers .section-feedback {
  margin-top: 76px;
}
@media screen and (max-width: 680px) {
  .page-towers .section-feedback {
    margin-top: unset;
  }
}

.towerschema_img {
  border-bottom-left-radius: 64px;
  border-bottom-right-radius: 64px;
}
@media screen and (max-width: 680px) {
  .towerschema_img {
    border-radius: 0;
  }
}

.page-projects-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-projects__inner {
  flex-grow: 1;
  width: calc(50% - 47px);
  margin-right: 47px;
}
@media screen and (max-width: 680px) {
  .page-projects__inner {
    margin-right: 0;
    width: 100%;
  }
}
.page-projects__inner:not(:last-child) {
  margin-bottom: 60px;
}
.page-projects__inner-img {
  overflow: hidden;
  border-radius: 14px;
  margin-bottom: 22px;
}
.page-projects__inner-img img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 230px;
  object-fit: cover;
}
@media screen and (max-width: 680px) {
  .page-projects__inner-img img {
    max-height: 230px;
    object-fit: cover;
    width: 100%;
  }
}
.page-projects__inner:nth-child(2n) {
  margin-right: 0;
}
.page-projects__title {
  font-weight: 700;
  font-size: 2.1rem;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #9e9e9e;
  font-family: "GetVoIP Grotesque";
}
.page-projects__link {
  color: #000;
  text-decoration: none;
}
.page-projects__link:hover {
  color: #3c8aa0;
}

.irs--flat {
  height: 0;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  background-color: transparent;
  background: url(../img/ellipse.svg) 10% 0% no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  font-size: 0 !important;
  color: #000;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}

.irs--flat .irs-to {
  background: url(../img/ellipse.svg) 95% 0% no-repeat;
  background-size: cover;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  display: none;
}

.irs--flat .irs-line,
.irs--flat .irs-bar {
  height: 0;
}

.irs--flat .irs-handle {
  display: none;
}

.irs--flat .irs-min,
.irs--flat .irs-max {
  display: none;
}

.custom-range-slider {
  position: absolute;
  width: 90%;
  bottom: 7px;
  left: 5%;
}

.header__top-nav {
  display: flex;
  justify-content: space-between;
  list-style: none;
}
@media screen and (max-width: 680px) {
  .header__top-nav {
    display: none;
  }
}
.header__top-nav li {
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 1;
  flex-grow: 1;
  padding: 0 10px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 980px) {
  .header__top-nav li {
    font-size: 1.5rem;
    padding: 0 5px;
  }
}
.header__top-nav li:not(:last-child) {
  margin-right: 15px;
}
.header__top-nav li:first-child {
  padding-left: 0;
}
.header__top-nav li:last-child {
  padding-right: 0;
}
.header__top-nav li a {
  border-top: 1px solid #9e9e9e;
  text-decoration: none;
  color: #000;
  width: 100%;
  display: block;
  padding: 17px 0 0;
  position: relative;
}
.header__top-nav li a:before {
  content: "";
  transition: all 0.3s ease;
  position: absolute;
  top: -1px;
  left: 0;
  height: 3px;
  width: 0;
  background: #3c8aa0;
}
@media screen and (max-width: 680px) {
  .header__top-nav li a:before {
    display: none;
  }
}
.header__top-nav li a.active:before, .header__top-nav li a:hover:before {
  width: 100%;
}
.header__top-nav li a.sub {
  background: url(../img/light/arrow-down.svg) 100% 19px no-repeat;
}
.header__top-nav li .submenu {
  list-style: none;
  display: none;
  position: absolute;
  z-index: 3;
  padding: 15px 0 10px 0;
  margin-left: -10px;
  width: 100%;
}
@media screen and (max-width: 980px) {
  .header__top-nav li .submenu {
    margin-left: -5px;
  }
}
@media screen and (max-width: 680px) {
  .header__top-nav li .submenu {
    position: relative;
    height: 0;
    opacity: 0;
    transition: all 0.3s;
    margin-left: -20px;
    padding: 0;
  }
}
.header__top-nav li .submenu li {
  background: #fff;
  font-size: 1.5rem;
}
.header__top-nav li .submenu li:first-child {
  padding-top: 10px;
}
.header__top-nav li .submenu li:last-child {
  padding-bottom: 10px;
}
.header__top-nav li .submenu li a {
  background: none;
  border: none;
  color: #000;
  padding: 8px 0;
}
.header__top-nav li .submenu li a:before {
  display: none;
}
.header__top-nav li .submenu li a:hover {
  color: #3c8aa0;
}
.header__top-nav li .submenu li:first-child {
  padding-left: 10px;
}
@media screen and (max-width: 980px) {
  .header__top-nav li .submenu li:first-child {
    padding-left: 5px;
  }
}
.header__top-nav li .submenu li:last-child {
  padding-right: 10px;
}
@media screen and (max-width: 980px) {
  .header__top-nav li .submenu li:last-child {
    padding-right: 5px;
  }
}
.header__top-nav li .submenu.open {
  display: block;
  margin-left: -10px;
  width: 100%;
}
@media screen and (max-width: 680px) {
  .header__top-nav li .submenu.open {
    margin-left: -20px;
  }
}
.header__top-nav li .submenu.open a {
  padding: 10px 0;
  color: #000;
}
.header__top-nav li .submenu.open a:before {
  display: none;
}
.header__top-nav li .submenu.open a:hover {
  color: #3c8aa0;
}
.header__top-nav li:hover .submenu {
  opacity: 1;
  height: auto;
  display: block;
}
.header__top-nav li.active a, .header__top-nav li:hover a {
  color: #3c8aa0;
}
.header__top-nav li.active a:before, .header__top-nav li:hover a:before {
  width: 100%;
}

.modal {
  background: #f7f7f7;
  border: 1px solid #9e9e9e;
  border-radius: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 661px;
  padding: 51px 28px;
  z-index: 100;
}
.modal_form__row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
  padding-right: 73px;
}
@media screen and (max-width: 680px) {
  .modal_form__row {
    padding-right: 0;
  }
}
.modal_form__field {
  position: relative;
  width: 48%;
}
@media screen and (max-width: 680px) {
  .modal_form__field {
    width: 100%;
  }
  .modal_form__field:not(:last-child) {
    margin-bottom: 10px;
  }
}
.modal_form__field .input_text {
  background: #ffffff;
  border-radius: 42px;
  border: none;
  padding: 10px 20px;
  width: 100%;
}
.modal_form__field input:focus ~ span {
  display: none;
}
.modal_form__field span {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
@media screen and (max-width: 680px) {
  .modal_form__field span {
    font-size: 1.3rem;
  }
}

.modal_presentation-title {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 35px;
  margin-bottom: 20px;
}
.modal_presentation .fancybox-button {
  position: absolute;
  width: 27px;
  height: 27px;
  top: 20px;
  right: 20px;
  background: url(../img/modal-close.svg) no-repeat;
}

.modal_towers {
  position: absolute;
  top: 78px;
  right: 32px;
  background: #ffffff;
  border: 1px solid #9e9e9e;
  border-radius: 14px;
  padding: 52px 28px 35px;
  width: 411px;
}
.modal_towers-title {
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 44px;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
}
.modal_towers-params {
  display: flex;
  gap: 25px;
  align-items: center;
  margin-bottom: 14px;
}
.modal_towers-params .params-item {
  display: flex;
  align-items: center;
}
.modal_towers-params .params-item-text {
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  margin-left: 12px;
}
.modal_towers-links {
  display: flex;
  margin-top: 35px;
  gap: 40px;
}
.modal_towers-links ul {
  list-style: none;
  font-weight: 800;
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.modal_towers-links ul li {
  margin-bottom: 0px;
}
.modal_towers-links ul a {
  text-decoration: none;
  color: #000;
}
.modal_towers-links ul span {
  color: #3c8aa0;
}
.modal_towers-links h4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.modal_towers-links .col:not(:first-child) {
  border-left: 1px solid #000000;
  padding-left: 30px;
}
.modal_towers p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}
.modal_towers .btn {
  margin-top: 35px;
  padding: 10px 49px;
}
.modal_towers-close {
  position: absolute;
  width: 27px;
  height: 27px;
  top: 20px;
  right: 20px;
  background: url(../img/modal-close.svg) no-repeat;
  cursor: pointer;
}

.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__top-right {
  display: flex;
  align-items: center;
  width: 35%;
  justify-content: flex-end;
}
@media screen and (max-width: 680px) {
  .header__top-right {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}
.header__top-logo {
  margin-top: 23px;
  margin-bottom: 26px;
  font-size: 0;
  display: block;
  width: 133.64px;
  height: 42px;
  background: url(../img/light/logo.svg) 50% 50% no-repeat;
  background-size: contain;
}
.header__top-logo a {
  display: block;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 680px) {
  .header__top-logo {
    margin: 22px 0;
    height: 33px;
    width: 95px;
    background-image: url(../img/light/logo-s.svg);
  }
}
.header__top-phone {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1;
  margin-right: 50px;
}
@media screen and (max-width: 980px) {
  .header__top-phone {
    font-size: 1.6rem;
    margin-right: 40px;
  }
}
@media screen and (max-width: 680px) {
  .header__top-phone {
    font-size: 0;
    width: 23px;
    height: 24px;
    background: url(../img/icon-phone.svg) no-repeat;
    margin-right: 0;
  }
}
.header__top-phone a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #000;
}
.header__top-theme-switcher .icon {
  cursor: pointer;
  mask: url(../img/light/icon-theme.svg) no-repeat;
  mask-size: contain;
  display: block;
  width: 32px;
  height: 24px;
  background: #000;
}
.header__top-theme-switcher .icon:hover {
  background: #3c8aa0;
}
.header__top-theme-switcher:hover svg path {
  stroke: #3c8aa0 !important;
}
@media screen and (max-width: 680px) {
  .header__top-theme-switcher {
    margin-right: 10px;
  }
}
.header__top-left {
  display: flex;
  align-items: center;
  width: 35%;
}

@media screen and (min-width: 980px) {
  .toast .header__top-phone {
    margin-right: 35px;
  }
  .toast .header__top-theme-switcher {
    margin-right: -201px;
    transition: 0.3s all;
    padding: 8px 40px 8px 15px;
    border: 1px solid #9e9e9e;
    border-radius: 30px;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
  }
  .toast .header__top-theme-switcher .text {
    position: relative;
  }
  .toast .header__top-theme-switcher .text:after {
    content: "Включить темную тему?";
    display: inline-block;
    margin-left: 10px;
  }
  .toast .header__top-theme-switcher .icon {
    background: #3c8aa0;
  }
  .toast .close {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    background: url(../img/modal-close.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}
.header__social {
  margin-left: 47px;
}
@media screen and (max-width: 680px) {
  .header__social {
    display: none;
  }
}
.header__social .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 13px;
}
.header__social .icon:hover svg path {
  fill: #3c8aa0 !important;
}
.header__social .icon.fav:hover svg path {
  fill: transparent !important;
  stroke: #3c8aa0 !important;
}

.header_mobile-email {
  display: block;
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 5px;
  color: #3c8aa0;
  text-decoration: none;
}
.header_mobile-phone {
  display: block;
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
}
.header_mobile__social .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 20px 0 0;
}
.header_mobile__info {
  display: none;
}

.add-nav {
  height: 0;
  opacity: 0;
  transition: 0.3s ease;
  position: absolute;
  width: 100%;
  background: #fff;
  left: 0;
  top: 80px;
  z-index: -1;
  padding-top: 11px;
  padding-bottom: 30px;
  pointer-events: none;
}
.add-nav.open {
  height: auto;
  opacity: 1;
  z-index: 10000;
  pointer-events: all;
}
.add-nav .header__add-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  list-style: none;
  max-width: 1024px;
  margin: 0 auto;
}
.add-nav .header__add-nav li {
  font-size: 1.7rem;
  line-height: 1;
  flex-grow: 1;
  padding: 0 10px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 980px) {
  .add-nav .header__add-nav li {
    font-size: 1.5rem;
    padding: 0 5px;
  }
}
.add-nav .header__add-nav li:not(:last-child) {
  margin-right: 15px;
}
.add-nav .header__add-nav li:first-child {
  padding-left: 0;
}
.add-nav .header__add-nav li:last-child {
  padding-right: 0;
}
.add-nav .header__add-nav li a {
  border-top: 1px solid #9e9e9e;
  padding: 17px 0;
  padding-bottom: 10px;
}
.add-nav .header__add-nav li .submenu {
  list-style: none;
  display: block;
}
.add-nav .header__add-nav li .submenu li {
  font-size: 1.4rem;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
  font-weight: normal;
}
.add-nav .header__add-nav li .submenu li a {
  border: none;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 10px;
}
.add-nav .header__add-nav li a {
  text-decoration: none;
  color: #000;
  width: 100%;
  display: block;
  position: relative;
}
.add-nav .header__add-nav li a:hover {
  color: #3c8aa0;
}
@media screen and (max-width: 680px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 79px;
    background-color: #fff;
    border-bottom: 1px solid #9e9e9e;
    z-index: 1750;
  }
  .header + .page_main {
    margin-top: 80px;
  }
  .nav-up {
    transform: translateY(-80px);
    opacity: 0;
    transition: all 0.3s ease-in;
  }
  .nav-down {
    transform: translateY(0px);
    opacity: 1;
    transition: all 0.3s ease-in;
  }
}
.header_mobile__social p {
  margin-bottom: 5px;
}

.footer {
  margin-top: 20px;
  padding-bottom: 23px;
}
@media screen and (max-width: 680px) {
  .footer {
    text-align: center;
    margin-top: 0;
  }
}
.footer .container {
  border-top: 1px solid #9e9e9e;
  padding-top: 43px;
}
.footer__row {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 680px) {
  .footer__row {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.footer__copy {
  font-size: 1.1rem;
  line-height: 1;
}
.footer__copy a {
  display: block;
  margin-top: 5px;
  color: #000;
  text-decoration: none;
}
.footer__copy a:hover {
  color: #3c8aa0;
}
.footer__nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  font-size: 1.3rem;
}
@media screen and (max-width: 680px) {
  .footer__nav {
    flex-wrap: wrap;
    flex-direction: column;
    display: none;
  }
}
.footer__nav .title {
  font-weight: 600;
  margin-bottom: 6px;
}
.footer__nav a {
  display: block;
  text-decoration: none;
  color: #000;
  margin-bottom: 7px;
}
.footer__nav a:hover {
  color: #3c8aa0;
}
.footer__nav-b {
  margin-bottom: 30px;
  font-weight: bold;
}
.footer__logo {
  margin-right: 80px;
  margin-bottom: 43px;
  font-size: 0;
  display: block;
  height: 47px;
  width: 149px;
  background: url(../img/light/logo.svg) 0 0 no-repeat;
  background-size: contain;
}
.footer .dev {
  font-size: 1.1rem;
}
.footer .dev a:hover svg path {
  fill: #3c8aa0 !important;
}
.footer .currency {
  font-size: 1.3rem;
}
.footer .currency span {
  color: #3c8aa0;
}

.footer_mobile {
  display: none;
}

@media screen and (max-width: 680px) {
  footer.footer {
    display: none;
  }
  .footer_mobile {
    display: block;
    text-align: center;
  }
  .footer_mobile-email {
    display: block;
    font-size: 1.7rem;
    line-height: 1.4;
    margin-bottom: 5px;
    color: #3c8aa0;
    text-decoration: none;
  }
  .footer_mobile-phone {
    display: block;
    font-size: 1.7rem;
    line-height: 1.4;
    margin-bottom: 20px;
    color: #000;
    text-decoration: none;
  }
  .footer_mobile-dev {
    margin-top: 20px;
  }
  .footer_mobile-dev svg {
    height: 15px;
  }
  .footer_mobile__copy {
    margin-top: 20px;
  }
  .footer_mobile__copy a {
    display: block;
  }
  .footer_mobile p {
    margin-bottom: 20px;
  }
  .footer_mobile .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 8px;
  }
  .footer__logo {
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    margin-bottom: 20px;
    background-image: url(../img/light/logo-s.svg);
  }
}
.page-body.dark {
  background: #252525;
  color: #fff;
}
.page-body.dark a {
  color: #fff;
}
.page-body.dark a:hover {
  color: #3c8aa0;
}
.page-body.dark .icon.fav:hover svg path {
  fill: none !important;
  stroke: #3c8aa0;
}
.page-body.dark .add-nav {
  background: #252525;
  color: #fff;
}
.page-body.dark .add-nav a {
  color: #fff;
}
.page-body.dark .page-contact__phone {
  color: #fff;
}
.page-body.dark .page-contact-row__col a {
  color: #3c8aa0;
}
.page-body.dark .product-item__tags .icon svg path {
  fill: none !important;
}
@media screen and (max-width: 680px) {
  .page-body.dark .header {
    background: #252525;
    color: #fff;
  }
}
.page-body.dark .currency span {
  color: #fff;
}
.page-body.dark .page-product-content__body-header a,
.page-body.dark .currency span.active {
  color: #3c8aa0;
}
.page-body.dark .product_list-rows .product-item__inner .product-item__action .add-fav {
  background: url(../img/dark/icon-star.svg) no-repeat;
  background-size: contain;
}
.page-body.dark .product_list-rows .product-item__inner .product-item__action .add-fav.active {
  background: url(../img/light/icon-star-h.svg) no-repeat;
  background-size: contain;
}
.page-body.dark .add-fav {
  background: url(../img/dark/icon-star.svg) no-repeat;
  background-size: contain;
}
.page-body.dark .custom-select {
  background: #353535;
}
.page-body.dark .page-product-contacts {
  background: #353535;
  color: #fff;
}
.page-body.dark .page-product-contacts .icon svg path {
  fill: #fff;
}
.page-body.dark .front-page-header {
  background: linear-gradient(0deg, #b9cbd9 0%, #b8cad8 100%);
}
.page-body.dark .front-page-header .container {
  background: url(../img/dark.png) no-repeat;
}
@media screen and (max-width: 680px) {
  .page-body.dark .front-page-header .container {
    background-position: 50% 100%;
    background-size: auto;
  }
}
.page-body.dark .front-page-header:after {
  background: #252525;
}
.page-body.dark .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff;
}
.page-body.dark .section-feedback-form {
  background: #353535;
}
.page-body.dark .section-feedback-form__radio label {
  border-color: #fff;
}
.page-body.dark .product-item__inner {
  background: #353535;
  border: none;
}
.page-body.dark .product_list-rows .product-item__tags li .icon {
  background: #454545;
}
.page-body.dark .page-nav__hamburger span {
  background-color: #fff;
}
.page-body.dark .page-nav__hamburger:hover span {
  background-color: #3c8aa0;
}
.page-body.dark .icon svg path {
  fill: #fff;
}
.page-body.dark .icon:hover svg path {
  fill: #3c8aa0;
}
.page-body.dark .icon.fav svg path {
  fill: none;
  stroke: #fff;
}
.page-body.dark .icon.fav:hover svg path {
  fill: none;
  stroke: #3c8aa0;
}
.page-body.dark .page__filter-header a {
  color: #9e9e9e;
}
.page-body.dark .page__filter-header li.active a {
  color: #fff;
}
.page-body.dark .icon-arrow-select {
  background: url(../img/dark/arrow-down.svg) 100% 50% no-repeat;
}
.page-body.dark .header__top-nav li a {
  border-top: 1px solid #9e9e9e;
}
@media screen and (max-width: 680px) {
  .page-body.dark .header__top-nav li a {
    border-top: none;
  }
}
.page-body.dark .header__top-nav li a.sub {
  background: url(../img/dark/arrow-down.svg) 100% 19px no-repeat;
}
.page-body.dark .page__filter-footer-clear {
  background: url(../img/dark/close.svg) 100% 50% no-repeat;
}
.page-body.dark .page__filter-footer-clear:hover {
  background: url(../img/light/close-h.svg) 100% 50% no-repeat;
}
.page-body.dark .irs--flat .irs-from,
.page-body.dark .irs--flat .irs-single,
.page-body.dark .irs--flat .irs-to {
  color: #fff;
}
.page-body.dark .header.open .main-nav {
  background: #252525;
}
.page-body.dark .header.open .header__top-nav li {
  background-color: #353535;
}
.page-body.dark .page__filter-inner .filter-checkbox,
.page-body.dark .page__filter-inner-item,
.page-body.dark .front-page-download .container {
  background: #353535;
}
.page-body.dark .page__filter-inner-item--check {
  background: none;
}
.page-body.dark .footer__logo,
.page-body.dark .header__top-logo {
  background: url(../img/dark/logo.svg) no-repeat;
  background-size: contain;
}
@media screen and (max-width: 680px) {
  .page-body.dark .footer__logo,
.page-body.dark .header__top-logo {
    background-image: url(../img/dark/logo-s.svg);
  }
}
.page-body.dark .page-product-order-contacts,
.page-body.dark .page-product-order-row,
.page-body.dark .page-product-content__details-item-icon,
.page-body.dark .select2-results,
.page-body.dark .front-page-download .container {
  background: #353535;
}
.page-body.dark .header__top-nav li .submenu li {
  background: #252525;
}
.page-body.dark .header__top-nav li .submenu a {
  border: none;
  background: none;
}
.page-body.dark .header__top-nav li a {
  color: #fff;
}
.page-body.dark .header__top-theme-switcher .icon svg path {
  fill: #252525;
  stroke: #fff;
}
.page-body.dark .header__top-theme-switcher .icon svg path:hover {
  stroke: #3c8aa0;
}
.page-body.dark .header__top-theme-switcher .icon {
  cursor: pointer;
  mask: url(../img/dark/icon-theme.svg) 50% 50% no-repeat;
  mask-size: contain;
  display: block;
  width: 32px;
  height: 24px;
  background: #fff;
}
.page-body.dark .header__top-theme-switcher .icon:hover {
  background: #3c8aa0;
}
.page-body.dark .page__filter-footer-result a {
  color: #3c8aa0;
}
@media screen and (max-width: 680px) {
  .page-body.dark .header__top-phone {
    background: url(../img/dark/icon-phone.svg) no-repeat;
  }
}
.page-body.dark .product-item__content {
  border: none;
}
.page-body.dark .section-feedback-form__radio input:checked + label {
  border-color: #3c8aa0;
}
.page-body.dark .footer .dev svg path {
  fill: #fff;
}
.page-body.dark .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background: url(../img/dark/arrow-down.svg) 100% 50% no-repeat;
}
.page-body.dark .page__filter-lot {
  background: none;
}
.page-body.dark .page-product-order-form__field span,
.page-body.dark .section-feedback-form__field span,
.page-body.dark .btn-img {
  color: #000;
}
.page-body.dark .page-return__item-icon {
  background: url(../img/dark/icon-back.svg) 0% 50% no-repeat;
}
.page-body.dark .product-slider .slick-arrow {
  background: url(../img/light/arrow-slick-w.svg) no-repeat;
}
.page-body.dark .product-slider .slick-arrow:hover {
  background: url(../img/light/arrow-slick-hover.svg) no-repeat;
}
.page-body.dark .page__filter-lot input[type=text] {
  color: #fff;
}
.page-body.dark .section-feedback-email,
.page-body.dark .footer a:hover {
  color: #3c8aa0;
}
.page-body.dark .btn-border {
  border-color: #fff;
  color: #fff;
}
.page-body.dark .btn-border:hover {
  border-color: #3c8aa0;
  color: #3c8aa0;
}
.page-body.dark .footer_mobile-dev svg path {
  fill: #fff;
}
.page-body.dark .modal,
.page-body.dark .modal_towers {
  background: #252525;
}
.page-body.dark .modal_towers-close,
.page-body.dark .modal_presentation .fancybox-button {
  background: url(../img/dark/modal-close.svg) no-repeat;
}
@media screen and (min-width: 980px) {
  .page-body.dark .toast .header__top-theme-switcher {
    border-color: #fff;
    color: #fff;
  }
  .page-body.dark .toast .header__top-theme-switcher .text {
    position: relative;
  }
  .page-body.dark .toast .header__top-theme-switcher .text:after {
    content: "Включить светлую тему?";
    display: inline-block;
    margin-left: 10px;
  }
  .page-body.dark .toast .header__top-theme-switcher .icon {
    background: #3c8aa0;
  }
  .page-body.dark .toast .header__top-theme-switcher .close {
    background: url(../img/dark/close.svg) no-repeat;
  }
}

.page-body.dark .irs--flat .irs-from,
.page-body.dark .irs--flat .irs-single,
.page-body.dark .irs--flat .irs-to {
  background: url(../img/dark/ellipse.svg) 95% 100% no-repeat;
}