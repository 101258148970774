.page-towers {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__inner {
    flex-grow: 1;
    width: calc(50% - 47px);
    max-width: calc(50% - 25px);
    margin-right: 47px;
    @media screen and (max-width: $sm) {
      margin-right: 0;
      width: 100%;
      max-width: 100%;
    }
    &:not(:last-child) {
      margin-bottom: 60px;
    }

    &-img {
      overflow: hidden;
      border-radius: 14px;
      margin-bottom: 39px;
      @media screen and (max-width: $sm) {
        margin-bottom: 22px;
      }
      img {
        display: block;
        max-width: 100%;
        width: 100%;
        // height: 100%;
        height: 230px;
        object-fit: cover;
        @media screen and (max-width: $sm) {
          max-height: 230px;
          object-fit: cover;
          width: 100%;
        }
      }
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 2.1rem;
    margin-bottom: 22px;
    font-family: "GetVoIP Grotesque";
  }
  &__proposes {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    @media screen and (max-width: $sm) {
      margin-bottom: 0px;
    }
    li {
      flex-grow: 1;
      border-top: 1px solid rgba(158, 158, 158, 1);
      padding-top: 14px;
      // font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.2rem;
      flex-basis: 50%;
      @media screen and (max-width: $sm) {
        font-size: 1.6rem;
        margin-bottom: 32px;
        width: 45%;
        flex-grow: 0;
        flex-basis: unset;
        span {
          font-size: 1.4rem;
        }
      }
      a {
        display: flex;
        justify-content: space-between;
        color: #000;
        text-decoration: none;
        margin-bottom: 7px;
        span {
          color: $accent-color;
        }
        &:hover {
          color: $accent-color;
        }
      }
      &:not(:first-child) {
        margin-left: 47px;
        @media screen and (max-width: $sm) {
          margin-left: 0;
        }
      }
    }
  }
  &-proposes {
    margin-top: 85px;
    margin-bottom: 45px;
    @media screen and (max-width: $sm) {
      margin-top: 68px;
      margin-bottom: initial;
    }
  }
  &-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 33px;
    margin-top: 25px;
    flex-wrap: wrap;
    @media screen and (max-width: $sm) {
      margin-top: 41px;
      margin-bottom: 41px;
    }
  }
  &__info {
    justify-content: space-between;
    display: flex;
    width: 50%;
    @media screen and (max-width: $sm) {
      width: 100%;
      margin-bottom: 22px;
    }
    &-col {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .value {
      font-family: "GetVoIP Grotesque";
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.2rem;
      margin-top: 13px;
    }
  }
  &__img {
    border-radius: 64px;
    margin-bottom: 86px;
    overflow: hidden;
    @media screen and (max-width: $sm) {
      border-radius: 0;
      margin: 0 -20px;
    }
    img {
      display: block;
      height: 334px;
      width: 100%;
      object-fit: cover;
      @media screen and (max-width: $sm) {
        height: 230px;
      }
    }
  }
  &__desc {
    width: 50%;
    font-size: 1.4rem;
    line-height: 28px;
    flex-shrink: 0;
    @media screen and (max-width: $sm) {
      width: 100%;
      line-height: 17px;
    }
  }
  &-wrapper-row {
    .page-towers__inner {
      display: flex;
      @media screen and (max-width: $sm) {
        flex-direction: column;
      }
    }
    .page-towers__inner {
      justify-content: space-between;
      margin-right: 0;
      max-width: 100%;
    }
    .page-towers__inner-img {
      width: 30%;
      flex-shrink: 0;
      margin-right: 47px;
      margin-bottom: 47px;

      @media screen and (max-width: $sm) {
        width: 100%;
        margin: 0;
      }
    }
    .page-towers__proposes {
      flex-grow: 1;
      @media screen and (max-width: $sm) {
        flex-direction: column;
      }
      li {
        width: 100%;
        @media screen and (max-width: $sm) {
          &:first-child {
            margin-top: 28px;
            // border: none;
          }
        }
      }
      .page-towers__proposes-content {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $sm) {
          font-weight: bold;
        }

        span {
          color: $accent-color;
        }
      }
      .page-towers__proposes-price {
        margin-top: 20px;
        font-weight: normal;
        margin-bottom: 28px;
        font-size: 1.3rem;
        @media screen and (max-width: $sm) {
          margin-top: 14px;
          margin-bottom: 22px;
        }
      }
      .btn {
        display: inline-block;
      }
    }
  }

  .section-chess {
    margin-bottom: 92px;
    @media screen and (max-width: $sm) {
      margin-bottom: 68px;
    }
  }
  .product-items {
    margin-top: 77px;
    @media screen and (max-width: $sm) {
      margin-top: initial;
    }
  }
  .product-slider {
    margin-bottom: 92px;
    @media screen and (max-width: $sm) {
      margin-top: 24px;
      margin-bottom: 41px;
    }
  }
  .page-img {
    position: relative;
    margin-top: 16px;
    .hint {
      position: absolute;
      top: 12px;
      right: 32px;
      background: #ffffff;
      border-radius: 42px;
      text-align: center;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.2rem;
      padding: 10px 49px;
      text-decoration: none;
      color: #000;
      span {
        color: $accent-color;
      }
    }
  }
  .section-feedback {
    margin-top: 76px;
    @media screen and (max-width: $sm) {
      margin-top: unset;
    }
  }
}

.towerschema_img {
  border-bottom-left-radius: 64px;
  border-bottom-right-radius: 64px;
  @media screen and (max-width: $sm) {
    border-radius: 0;
  }
}
