.footer {
  margin-top: 20px;
  padding-bottom: 23px;
  @media screen and (max-width: $sm) {
    text-align: center;
    margin-top: 0;
  }
  .container {
    border-top: 1px solid $line-color;
    padding-top: 43px;
  }
  &__row {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $sm) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__copy {
    // margin-bottom: 10px;
    font-size: 1.1rem;
    line-height: 1;
    a {
      display: block;
      margin-top: 5px;
      color: #000;
      text-decoration: none;
      &:hover {
        color: $accent-color;
      }
    }
  }
  &__nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 1.3rem;
    @media screen and (max-width: $sm) {
      flex-wrap: wrap;
      flex-direction: column;
      display: none;
    }
    .title {
      font-weight: 600;
      margin-bottom: 6px;
    }
    a {
      display: block;
      text-decoration: none;
      color: #000;
      margin-bottom: 7px;
      &:hover {
        color: $accent-color;
      }
    }
    &-b {
      margin-bottom: 30px;
      font-weight: bold;
    }
  }
  &__logo {
    margin-right: 80px;
    margin-bottom: 43px;
    font-size: 0;
    display: block;
    height: 47px;
    width: 149px;
    background: url(../img/light/logo.svg) 0 0 no-repeat;
    background-size: contain;
  }
  .dev {
    font-size: 1.1rem;
    a:hover {
      svg {
        path {
          fill: $accent-color !important;
        }
      }
    }
  }
  .currency {
    font-size: 1.3rem;
    span {
      color: $accent-color;
    }
  }
}

.footer_mobile {
  display: none;
}
@media screen and (max-width: $sm) {
  footer.footer {
    display: none;
  }
  .footer_mobile {
    display: block;
    text-align: center;
    &-email {
      display: block;
      font-size: 1.7rem;
      line-height: 1.4;
      margin-bottom: 5px;
      color: $accent-color;
      text-decoration: none;
    }
    &-phone {
      display: block;
      font-size: 1.7rem;
      line-height: 1.4;
      margin-bottom: 20px;
      color: #000;
      text-decoration: none;
    }
    &-dev {
      margin-top: 20px;
      svg {
        height: 15px;
      }
    }
    &__copy {
      margin-top: 20px;
      a {
        display: block;
      }
    }
    p {
      margin-bottom: 20px;
    }
    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0 8px;
    }
  }

  .footer__logo {
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    margin-bottom: 20px;
    background-image: url(../img/light/logo-s.svg);
  }
}
