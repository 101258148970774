.front-page {
  &-header {
    margin-top: 42px;
    height: 369px;
    background: linear-gradient(
      0deg,
      rgba(#eefdfc, 1) 0%,
      rgba(#ecfdfc, 1) 100%
    );
    position: relative;
    @media screen and (max-width: $sm) {
      margin-top: 0;
      height: 140px;
      margin-bottom: 60px;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background: url(../img/light.png) -170px 0 no-repeat;
      position: relative;
      z-index: 2;
      transform: translateX(-30%);
      @media screen and (max-width: $sm) {
        transform: none;
        background-position: 50px 100%;
        background-size: auto 100%;
        justify-content: start;
      }
    }
    &:after {
      content: "";
      display: block;
      height: 100%;
      width: 50%;
      position: absolute;
      background: #fff;
      left: 50%;
      top: 0;
      z-index: 1;
    }
    .front-page-header-title {
      transform: translateX(30%);
      position: relative;
      width: 100%;
      @media screen and (max-width: $sm) {
        transform: none;
      }
    }
    h1 {
      font-family: "GetVoIP Grotesque";
      text-transform: uppercase;
      font-weight: 700;
      font-size: 5.9rem;
      line-height: 1;
      letter-spacing: 0.05em;
      text-align: left;
      padding-left: 210px;
      display: inline-block;
      margin-top: 40px;
      // float: right;
      @media screen and (max-width: $md) {
        padding-left: 0;
        font-size: 49px;
        margin-top: 0;
      }
      @media screen and (max-width: $sm) {
        font-size: 2.1rem;
      }
      span {
        display: block;
        padding-left: 130px;
        @media screen and (max-width: $sm) {
          padding-left: 50px;
        }
      }
    }
    .front-page-header-link {
      position: absolute;
      top: 40px;
      right: 0;
      text-decoration: none;
      color: #000;
      font-size: 1.7rem;
      line-height: 2.2rem;
      cursor: pointer;
      span {
        color: $accent-color;
      }
      &:hover {
        color: $accent-color;
      }
      @media screen and (max-width: $md) {
        top: 0;
      }
      @media screen and (max-width: $sm) {
        bottom: -80px;
        top: auto;
        left: 0px;
        font-size: 1.4rem;
      }
    }
  }
  &-special {
    .title {
      text-transform: none !important;
    }
    @media screen and (max-width: $md) {
      .flex {
        flex-direction: column-reverse;
      }
      img {
        max-width: 100%;
      }
      .front-page-txt-content {
        margin-bottom: 0;
      }
    }
  }
  &-download {
    .container {
      display: flex;
      border-radius: 64px;
      background: $gray;
      justify-content: center;
      margin-top: 120px;
      margin-bottom: 125px;
      padding: 0;
      width: 90%;
      @media screen and (max-width: $md) {
        width: calc(100% - 30px);
        flex-direction: column;
        border-radius: 14px;
        margin-top: 60px;
        margin-bottom: 0;
      }
    }
    &__text {
      text-align: left;
      padding: 100px 50px 85px 0px;

      @media screen and (max-width: $md) {
        padding: 0px 30px 41px;
      }
    }
    &__img {
      width: 40%;
      position: relative;
      margin-right: auto;
      @media screen and (max-width: $md) {
        width: 100%;
        // transform: translateY(-120px);
        margin-top: -120px;
      }
      img {
        position: absolute;
        top: -11%;
        left: -35%;
        max-width: 200%;
        // width: 750px;
        @media screen and (max-width: $md) {
          position: static;
          max-width: 100%;
        }
      }
    }
    .btn {
      margin-top: 41px;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.2rem;
      padding: 10px 49px;
      display: inline-block;
      @media screen and (max-width: $sm) {
        padding: 10px 60px;
        font-size: 1.4rem;
      }
    }
    h2 {
      text-transform: none;
      font-weight: 700;
      font-size: 3rem;
      line-height: 1.2;
      margin-bottom: 15px;

      @media screen and (max-width: $sm) {
        font-size: 2.1rem;
        max-width: 200px;
      }
    }
    p {
      margin-bottom: 0 !important;
    }
  }
  &-txt-img,
  &-txt-content {
    width: 50%;
    @media screen and (max-width: $md) {
      width: 100%;
      padding: 0 !important;
    }
  }
  &-txt-img {
    padding-left: 26px;
    @media screen and (max-width: $sm) {
      margin-bottom: 10px;
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
      height: 240px;
      object-fit: cover;
    }
    img {
      border-radius: 64px;
      @media screen and (max-width: $md) {
        width: 100%;
      }
      @media screen and (max-width: $sm) {
        border-radius: 0px;
        width: 100%;
        max-width: 100%;
        height: 240px;
        object-fit: cover;
      }
      @media screen and (min-height: 1200px) {
        max-height: 60vh;
      }
    }
  }
  &-txt-content {
    padding-right: 47px;
    .btn {
      margin-top: 20px;
    }
    .subtitle {
      color: $accent-color;
      font-size: 2rem;
      margin-bottom: 30px;
      @media screen and (max-width: $sm) {
        margin-bottom: 22px;
        font-size: 1.4rem;
      }
    }
    .title {
      font-family: "GetVoIP Grotesque";
      font-size: 3rem;
      margin-bottom: 15px;
      text-transform: uppercase;
      @media screen and (max-width: $sm) {
        font-size: 2.1rem;
        margin-bottom: 22px;
      }
    }
  }

  &-propose {
    .flex {
      margin-bottom: 50px;
      @media screen and (max-width: $sm) {
        margin-bottom: unset;
      }
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 51px;
      grid-row-gap: 50px;
      @media screen and (max-width: $sm) {
        display: block;
      }
      .col {
        margin-bottom: 30px;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 1.7rem;
      padding-top: 19px;
      margin-bottom: 22px;
      margin-top: 25px;
      border-top: 1px solid $line-color;
      @media screen and (max-width: $sm) {
        margin-top: 12px;
        font-size: 1.6rem;
      }
      a {
        text-decoration: none;
        color: #000;
        &:hover {
          color: $accent-color;
        }
      }

      span {
        color: $accent-color;
        @media screen and (max-width: $sm) {
          font-size: 1.4rem;
        }
      }
    }
    .item {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      @media screen and (max-width: $sm) {
        border-top: none;
        & + .front-page-propose-header {
          border-top: 1px solid $line-color;
        }
      }

      a {
        color: #000;
        text-decoration: none;
        font-size: 1.6rem;
        line-height: 1.1;
        display: inline-block;
        width: 140px;
        @media screen and (max-width: $sm) {
          font-size: 1.4rem;
          width: 115px;
        }
        &:hover {
          color: $accent-color;
        }
      }
      .price {
        margin-left: 36px;
        font-size: 1.3rem;
        @media screen and (max-width: $sm) {
          font-size: 1.2rem;
        }
      }
    }
    img {
      width: 100%;
      border-radius: 14px;
      margin-bottom: 25px;
      height: 188px;
      object-fit: cover;
      @media screen and (max-width: $sm) {
        margin-bottom: 0;
        & + .front-page-propose-header {
          // border-top: none;
        }
      }
    }
  }
  &-img {
    margin-top: 34px;
    position: relative;
    margin-bottom: 41px;
    img {
      max-width: 100%;
    }
    @media screen and (max-width: $md) {
      img {
        width: 100%;
        min-height: 210px;
        object-fit: cover;
        border-radius: 0;
        max-height: 70vh;
      }
    }
    .hint {
      position: absolute;
      top: 26px;
      right: 32px;
      background: #ffffff;
      border-radius: 42px;
      text-align: center;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.2rem;
      padding: 10px 49px;
      text-decoration: none;
      color: #000;
      span {
        color: $accent-color;
      }
    }
  }
}

.page_main {
  .section-feedback {
    margin-top: 75px;
    @media screen and (max-width: $sm) {
      margin-top: 0;
    }
  }
  .page-section {
    margin-bottom: 80px;
    @media screen and (max-width: $sm) {
      margin-bottom: 68px;
    }
  }

  .section-chess {
    margin-bottom: 60px;
    .txt-content .title {
      font-size: 3rem;
      margin-bottom: 32px;
      @media screen and (max-width: $sm) {
        margin-bottom: 22px;
        font-size: 2.1rem;
      }
    }
    .fdr {
      .front-page-txt-img {
        margin-right: 25px;
        padding-right: 25px;
        @media screen and (max-width: $sm) {
          margin-right: -20px;
        }
      }
    }
  }

  .page__filter-inner-row {
    margin-bottom: 30px;
    @media screen and (max-width: $sm) {
      margin-bottom: 22px;
    }
  }
  .page__filter-type {
    @media screen and (max-width: $sm) {
      margin-bottom: 22px;
    }
  }
  .product-items {
    @media screen and (max-width: $sm) {
      margin-bottom: 68px;
    }
  }

  @media screen and (max-width: $md) {
    .section-chess {
      .flex {
        flex-direction: column-reverse;
        .front-page-txt-img {
          margin-bottom: 41px;
        }
      }
    }
    .mobile .page__filter-type {
      margin-bottom: 41px;
    }

    .page-section.page-section--about {
      margin-bottom: 41px;
      .title {
        margin-bottom: 10px;
      }
    }
    .front-page-download.front-page-download {
      margin-top: 160px;
      margin-bottom: 68px;
    }
    .front-page-special {
      .front-page-txt-img {
        margin-bottom: 40px;
      }
    }
  }

  .product-slider {
    @media screen and (max-width: $sm) {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: $sm) {
  .download_presentation {
    margin-top: 40px;
  }
}
.pdr0 {
  padding-right: 0 !important;
}
._border--no {
  border: none !important;
}
.text-size-140 {
  font-size: 1.4rem !important;
}
._svg150 svg {
  height: 1.5em;
  width: 1.5em;
  flex-shrink: 0;
  max-width: none;
}
.text-color-black {
  color: #000;
  fill: #000;
  stroke: #000;
}
.ml--large {
  margin-left: 65px !important;
}
.form__action-dropdown {
  box-shadow: 0 5px 13px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding-top: 20px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 7px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1055;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    li {
      a {
        font-size: 11px;
        color: #000;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        &:hover {
          color: #3c8aa0;
        }
      }
    }
    li:not(:last-child) {
      margin-bottom: 13px;
    }
  }
}
.form__action-dropdown-show-all a {
  font-size: 11px;
  color: #000;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 840px) {
  .form__action-dropdown {
    width: 360px;
  }
}
