.page-body.dark {
  background: #252525;
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: $accent-color;
    }
  }
  .icon.fav {
    &:hover {
      svg path {
        fill: none !important;
        stroke: $accent-color;
      }
    }
  }

  .add-nav {
    background: #252525;
    color: #fff;
    a {
      color: #fff;
    }
  }
  .page-contact__phone {
    color: #fff;
  }
  .page-contact-row__col a {
    color: $accent-color;
  }
  .product-item__tags .icon svg path {
    fill: none !important;
  }
  .header {
    @media screen and (max-width: $sm) {
      background: #252525;
      color: #fff;
    }
  }
  .currency span {
    color: #fff;
  }
  .page-product-content__body-header a,
  .currency span.active {
    color: $accent-color;
  }

  .product_list-rows .product-item__inner .product-item__action .add-fav {
    background: url(../img/dark/icon-star.svg) no-repeat;
    background-size: contain;
    &.active {
      background: url(../img/light/icon-star-h.svg) no-repeat;
      background-size: contain;
    }
  }
  .add-fav {
    background: url(../img/dark/icon-star.svg) no-repeat;
    background-size: contain;
  }
  .custom-select {
    background: $dark-gray;
  }
  .page-product-contacts {
    background: $dark-gray;
    color: #fff;
    .icon svg path {
      fill: #fff;
    }
  }
  .front-page-header {
    background: linear-gradient(
      0deg,
      rgba(#b9cbd9, 1) 0%,
      rgba(#b8cad8, 1) 100%
    );

    .container {
      background: url(../img/dark.png) no-repeat;
      @media screen and (max-width: $sm) {
        background-position: 50% 100%;
        background-size: auto;
      }
    }
    &:after {
      background: #252525;
    }
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #fff;
  }
  .section-feedback-form {
    background: $dark-gray;
  }
  .section-feedback-form__radio label {
    border-color: #fff;
  }
  .product-item__inner {
    background: $dark-gray;
    border: none;
  }
  .product_list-rows .product-item__tags li .icon {
    background: #454545;
  }

  .page-nav__hamburger {
    span {
      background-color: #fff;
    }
    &:hover {
      span {
        background-color: $accent-color;
      }
    }
  }
  .icon {
    svg path {
      fill: #fff;
    }
    &:hover {
      svg path {
        fill: $accent-color;
      }
    }
    &.fav {
      svg path {
        fill: none;
        stroke: #fff;
      }
      &:hover {
        svg path {
          fill: none;
          stroke: $accent-color;
        }
      }
    }
  }

  .page__filter-header {
    a {
      color: $line-color;
    }
    li.active {
      a {
        color: #fff;
      }
    }
  }
  .icon-arrow-select {
    background: url(../img/dark/arrow-down.svg) 100% 50% no-repeat;
  }
  .header__top-nav li a {
    border-top: 1px solid $line-color;
    @media screen and (max-width: $sm) {
      border-top: none;
    }
  }
  .header__top-nav li a.sub {
    background: url(../img/dark/arrow-down.svg) 100% 19px no-repeat;
  }

  .page__filter-footer-clear {
    background: url(../img/dark/close.svg) 100% 50% no-repeat;
    &:hover {
      background: url(../img/light/close-h.svg) 100% 50% no-repeat;
    }
  }

  .irs--flat .irs-from,
  .irs--flat .irs-single,
  .irs--flat .irs-to {
    color: #fff;
  }
  .header.open {
    .main-nav {
      background: #252525;
    }
    .header__top-nav {
      li {
        background-color: #353535;
      }
    }
  }
  .page__filter-inner .filter-checkbox,
  .page__filter-inner-item,
  .front-page-download .container {
    background: $dark-gray;
  }
  .page__filter-inner-item--check {
    background: none;
  }

  .footer__logo,
  .header__top-logo {
    background: url(../img/dark/logo.svg) no-repeat;
    background-size: contain;

    @media screen and (max-width: $sm) {
      background-image: url(../img/dark/logo-s.svg);
    }
  }
  .page-product-order-contacts,
  .page-product-order-row,
  .page-product-content__details-item-icon,
  .select2-results,
  .front-page-download .container {
    background: $dark-gray;
  }
  .header__top-nav li .submenu {
    li {
      background: #252525;
    }
    a {
      border: none;
      background: none;
    }
  }

  .header__top-nav li a {
    color: #fff;
  }
  .header__top-theme-switcher .icon svg path {
    fill: #252525;
    stroke: #fff;
    &:hover {
      stroke: $accent-color;
    }
  }
  .header__top-theme-switcher {
    .icon {
      cursor: pointer;
      mask: url(../img/dark/icon-theme.svg) 50% 50% no-repeat;
      mask-size: contain;
      display: block;
      width: 32px;
      height: 24px;
      background: #fff;
      &:hover {
        background: $accent-color;
      }
    }
  }

  .page__filter-footer-result a {
    color: $accent-color;
  }
  @media screen and (max-width: $sm) {
    .header__top-phone {
      background: url(../img/dark/icon-phone.svg) no-repeat;
    }
  }

  .product-item__content {
    border: none;
  }
  .section-feedback-form__radio input:checked + label {
    border-color: #3c8aa0;
  }
  .footer .dev svg path {
    fill: #fff;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    background: url(../img/dark/arrow-down.svg) 100% 50% no-repeat;
  }
  .page__filter-lot {
    background: none;
  }
  .page-product-order-form__field span,
  .section-feedback-form__field span,
  .btn-img {
    color: #000;
  }
  .page-return__item-icon {
    background: url(../img/dark/icon-back.svg) 0% 50% no-repeat;
  }
  .product-slider .slick-arrow {
    background: url(../img/light/arrow-slick-w.svg) no-repeat;
    &:hover {
      background: url(../img/light/arrow-slick-hover.svg) no-repeat;
    }
  }

  .page__filter-lot input[type="text"] {
    color: #fff;
  }
  .section-feedback-email,
  .footer a:hover {
    color: $accent-color;
  }

  .btn-border {
    border-color: #fff;
    color: #fff;
    &:hover {
      border-color: $accent-color;
      color: $accent-color;
    }
  }
  .footer_mobile {
    &-dev {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  .modal,
  .modal_towers {
    background: #252525;
  }
  .modal_towers-close,
  .modal_presentation .fancybox-button {
    background: url(../img/dark/modal-close.svg) no-repeat;
  }
  @media screen and (min-width: $md) {
    .toast {
      .header__top-theme-switcher {
        border-color: #fff;
        color: #fff;
        .text {
          position: relative; 
          &:after {
           content: "Включить светлую тему?";
           display: inline-block;
           margin-left: 10px;
         }
        }
        // &:after {
        //   content: "Включить светлую тему?";
        // }
        .icon {
          background: $accent-color;
        }
        .close {
          background: url(../img/dark/close.svg) no-repeat;
        }
      }
    }
  }
}

.page-body.dark .irs--flat .irs-from,
.page-body.dark .irs--flat .irs-single,
.page-body.dark .irs--flat .irs-to {
  background: url(../img/dark/ellipse.svg) 95% 100% no-repeat;
}
