.page-nav__hamburger {
  width: 25px;
  height: 15px;
  position: relative;
}
.page-nav__hamburger {
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: #000;
    border-radius: 3px;
    transition: all ease-in-out 0.25s;
  }
  &:hover {
    span {
      background-color: $accent-color;
    }
  }
}
.page-nav__hamburger span:nth-child(1) {
  top: 0px;
}
.page-nav__hamburger span:nth-child(2) {
  top: 5px;
  @media screen and (max-width: $sm) {
    width: 60%;
  }
}
.page-nav__hamburger span:nth-child(3) {
  top: 10px;
}

.page-nav__hamburger.active {
  span:nth-child(1) {
    transform: rotate(-45deg);
    margin-bottom: 0;
    margin-top: 5px;
  }
  span:nth-child(2) {
    display: none;
  }
  span:nth-child(3) {
    transform: rotate(45deg);
    margin-top: -5px;
  }
}
