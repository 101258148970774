.page-projects {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__inner {
    flex-grow: 1;
    width: calc(50% - 47px);
    margin-right: 47px;
    @media screen and (max-width: $sm) {
      margin-right: 0;
      width: 100%;
    }
    &:not(:last-child) {
      margin-bottom: 60px;
    }

    &-img {
      overflow: hidden;
      border-radius: 14px;
      margin-bottom: 22px;

      img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: 230px;
        object-fit: cover;
        @media screen and (max-width: $sm) {
          max-height: 230px;
          object-fit: cover;
          width: 100%;
        }
      }
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 2.1rem;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $line-color;
    font-family: "GetVoIP Grotesque";
  }
  &__link {
    color: #000;
    text-decoration: none;
    &:hover {
      color: $accent-color;
    }
  }
}
