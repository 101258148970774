.custom-select {
  position: relative;
  z-index: 1051;
  .page__filter-label {
    transform: translateY(-1px);
  }
}
.custom-select__title {
  display: block;
  font-size: 1.4rem;
  @media screen and (max-width: $sm) {
    font-size: 1.2rem;
  }
}
.select2-results {
  font-size: 1.7rem;
  background: #f7f7f7;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  @media screen and (max-width: $sm) {
    font-size: 1.5rem;
  }
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background: none;
  color: unset;
  // color: #000;
  // color: $accent-color;
  // display: none;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable:hover {
  color: $accent-color;
}
.select2-results__option.select2-results__option--selectable.select2-results__option--selected {
  display: none;
}
.select2-container--default .select2-results__option--selected {
  background: none;
}
.select2-dropdown {
  border: none;
  background: none;
  margin-left: -15px;
  width: calc(100% + 45px) !important;
}
.select2-results__option {
  padding: 6px 15px;
}

.custom-select .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  line-height: 2.2rem;
  font-size: 1.7rem;
  @media screen and (max-width: $sm) {
    font-size: 1.5rem;
  }
}
.custom-select .select2-selection--single {
  border-radius: 0;
  border: 0;
  height: auto;
  padding: 0;
  outline: none;
  background: transparent;
}
.custom-select-label {
  display: block;
  width: 100%;
}
.select2 {
  width: 100% !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: -25px;
  width: 20px;
  height: 20px;
  background: url(../img/light/arrow-down.svg) 100% 50% no-repeat;
  border: none;
}
.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  background: url(../img/light/arrow-up.svg) 100% 50% no-repeat;
}
.custom-select_border-white
  .select2-container--default
  .select2-selection--single {
  background-color: transparent;
  border: 2px solid #fff;
}
.custom-select_border-white
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff;
}

.custom-select_border-black
  .select2-container--default
  .select2-selection--single {
  background-color: transparent;
  border: 2px solid #000;
}
.custom-select_border-black
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #000;
}

.custom-select_black .select2-container--default .select2-selection--single {
  background-color: transparent;
}
.custom-select_black .page-category__label-title {
  margin-left: 0;
  margin-right: 0;
  text-transform: none;
}

.custom-select__dropdown-title-link {
  display: block;
  padding-left: 15px;
  padding-right: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #444;
  line-height: 38px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.custom-select__dropdown-title-link.active .custom-select__dropdown-title-icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.custom-select__dropdown-title-icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 21px;
  height: 12px;
}
.custom-select__dropdown {
  background-color: #fff;
  width: 100%;
  width: 230px;
  max-height: 210px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  right: -18px;
  margin-top: 10px;
  display: none;
}
.custom-select__dropdown.active {
  display: block;
}
.custom-select__dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.custom-select__dropdown ul li a {
  text-transform: uppercase;
  padding: 12px;
  padding-right: 40px;
  font-size: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000;
  position: relative;
}
.custom-select__dropdown ul li a:hover {
  background-color: #f5f5f5;
  color: #000;
}
.custom-select__dropdown ul li a:hover {
  color: $accent-color;
}
.custom-select__dropdown ul li a.active::before {
  content: "";
  display: block;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-image: url(../img/svg-icons/close.svg);
  background-size: 12px 12px;
  background-position: center center;
  background-repeat: no-repeat;
}

.page-category__label-content .flex {
  flex-direction: row;
}
.custom-range_to,
.custom-range_from {
  width: 50%;
  white-space: nowrap;
}
.custom-range_to {
  text-align: right;
}
