.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-right {
    display: flex;
    align-items: center;
    width: 35%;
    justify-content: flex-end;
    @media screen and (max-width: $sm) {
      justify-content: flex-start;
      flex-direction: row-reverse;
    }
  }
  &-logo {
    margin-top: 23px;
    margin-bottom: 26px;
    font-size: 0;
    display: block;
    width: 133.64px;
    height: 42px;
    background: url(../img/light/logo.svg) 50% 50% no-repeat;
    background-size: contain;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width: $sm) {
      margin: 22px 0;
      height: 33px;
      width: 95px;
      background-image: url(../img/light/logo-s.svg);
    }
  }
  &-phone {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1;
    margin-right: 50px;
    @media screen and (max-width: $md) {
      font-size: 1.6rem;
      margin-right: 40px;
    }
    @media screen and (max-width: $sm) {
      font-size: 0;
      width: 23px;
      height: 24px;
      background: url(../img/icon-phone.svg) no-repeat;
      margin-right: 0;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: #000;
    }
  }
  &-theme-switcher {
    .icon {
      cursor: pointer;
      mask: url(../img/light/icon-theme.svg) no-repeat;
      mask-size: contain;
      display: block;
      width: 32px;
      height: 24px;
      background: #000;
      &:hover {
        background: $accent-color;
      }
    }

    &:hover {
      svg path {
        stroke: $accent-color !important;
      }
    }
    @media screen and (max-width: $sm) {
      margin-right: 10px;
    }
  }
  &-left {
    display: flex;
    align-items: center;
    width: 35%;
  }
}
@media screen and (min-width: $md) {
  .toast {
    .header__top-phone {
      // display: none;
      margin-right: 35px;
    }
    .header__top-theme-switcher {
      margin-right: -201px;
      transition: 0.3s all;
      padding: 8px 40px 8px 15px;
      border: 1px solid $line-color;
      border-radius: 30px;
      display: flex;
      position: relative;
      align-items: center;
      cursor: pointer;
      .text {
        position: relative;
        &:after {
          content: "Включить темную тему?";
          display: inline-block;
          margin-left: 10px;
        }
      }

      .icon {
        background: $accent-color;
      }
    }
    .close {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      background: url(../img/modal-close.svg) no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
}
.header__social {
  margin-left: 47px;
  @media screen and (max-width: $sm) {
    display: none;
  }
  .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 13px;
    &:hover {
      svg path {
        fill: $accent-color !important;
      }
    }
    &.fav:hover {
      svg path {
        fill: transparent !important;
        stroke: $accent-color !important;
      }
    }
  }
}
.header_mobile {
  &-email {
    display: block;
    font-size: 1.7rem;
    line-height: 1.4;
    margin-bottom: 5px;
    color: $accent-color;
    text-decoration: none;
  }
  &-phone {
    display: block;
    font-size: 1.7rem;
    line-height: 1.4;
    margin-bottom: 10px;
    color: #000;
    text-decoration: none;
  }
  &__social {
    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0 20px 0 0;
    }
  }

  &__info {
    display: none;
  }
}

.add-nav {
  // display: none;
  height: 0;
  opacity: 0;
  transition: 0.3s ease;
  position: absolute;
  width: 100%;
  background: #fff;
  left: 0;
  top: 80px;
  z-index: -1;
  padding-top: 11px;
  padding-bottom: 30px;
  pointer-events: none;
  &.open {
    // display: block;
    height: auto;
    opacity: 1;
    z-index: 10000;
    pointer-events: all;
  }
  .header__add-nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    list-style: none;
    max-width: 1024px;
    margin: 0 auto;
    // border-top: 1px solid $line-color;
    // padding-top: 17px;
    li {
      font-size: 1.7rem;
      line-height: 1;
      flex-grow: 1;
      padding: 0 10px;
      position: relative;
      width: 100%;
      @media screen and (max-width: $md) {
        font-size: 1.5rem;
        padding: 0 5px;
      }
      &:not(:last-child) {
        margin-right: 15px;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      a {
        border-top: 1px solid $line-color;
        padding: 17px 0;
        padding-bottom: 10px;
      }

      .submenu {
        list-style: none;
        display: block;
        li {
          font-size: 1.4rem;
          padding-left: 0;
          margin-left: 0;
          margin-bottom: 0;
          font-weight: normal;
          a {
            border: none;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 10px;
          }
        }
      }
      a {
        text-decoration: none;
        color: #000;
        width: 100%;
        display: block;
        position: relative;
        &:hover {
          color: $accent-color;
        }
      }
      &:last-child {
        .submenu {
          li {
            // font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 79px;
    background-color: #fff;
    border-bottom: 1px solid #9e9e9e;
    z-index: 1750;
  }
  .header + .page_main {
    margin-top: 80px;
  }
  .nav-up {
    transform: translateY(-80px);
    opacity: 0;
    transition: all 0.3s ease-in;
  }
  .nav-down {
    transform: translateY(0px);
    opacity: 1;
    transition: all 0.3s ease-in;
  }
}

.header_mobile__social p {
  margin-bottom: 5px;
}
