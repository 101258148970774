.slider-gallery .slick-current.slick-active {
  outline: none !important;
  margin: 0;
  opacity: 1;
  min-height: 420px;
  object-fit: cover;
  border-radius: 64px;

  @media screen and (max-width: 980px) {
    border-radius: 0;
    min-height: 330px;
    height: 330px;
  }
  img {
    outline: none !important;
    margin: 0;
    opacity: 1;
    min-height: 420px;
    object-fit: cover;
    border-radius: 64px;

    @media screen and (max-width: 980px) {
      // border-radius: 14px;
      border-radius: 0;
      min-height: 330px;
    }
  }
}

.slider-gallery {
  .slick-slide img {
    height: 100%;
    max-width: 100%;
    margin: auto !important;
    object-fit: cover;
    border-radius: 64px;
    @media screen and (max-width: 980px) {
      border-radius: 0;
      height: 330px;
      width: 100%;
    }
  }
  .slick-track {
    height: 500px;
    display: flex !important;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .slick-slide {
    height: auto; // ← that must not be ignored
  }
  .item-slick {
    // height: 100%;
    // width: auto !important;
    // margin: 0 auto !important;
  }
}

// .item-slick {
//   max-height: 500px;
//   margin: 0 auto;
// }

.slider-for img {
  width: 100%;
  height: 100%;
  //   min-height: 100%;
}

.slider-nav {
  margin: 26px auto 0px;
  @media screen and (max-width: $sm) {
    display: none;
  }
  .slick-arrow {
    display: none !important;
  }
}

.slider-nav .item-slick {
  max-width: 144px;
  height: 96px;
  margin-right: 13px !important;
  margin-left: 0;
  border-radius: 14px !important;
  outline: none !important;
  opacity: 0.3;
  cursor: pointer;
  min-height: auto !important;
  @media screen and (max-width: 980px) {
    max-width: 135px;
  }
}

.slider-nav .item-slick {
  max-width: 100%;
  background-size: cover;
  background-position: center;
}
.slider-nav .slick-current {
  .item-slick {
    opacity: 1;
  }
}
.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: 14px;
  z-index: 50;
  width: 48px;
  height: 48px;
  border: none;
  background: #000 url(../img/light/arrow-slick-w.svg) 40% 50% no-repeat;
  transform: translateY(-50%);
  border-radius: 100px;
  background-size: 15px;
  font-size: 0;
  cursor: pointer;
  @media screen and (max-width: $sm) {
    width: 32px;
    height: 32px;
    background-size: 10px;
  }
  &.slick-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.slick-prev {
    left: 20px;
  }

  &.slick-next {
    right: -28px;
    transform-origin: left;
    transform: translateY(-50%) rotate(180deg);
    @media screen and (max-width: $sm) {
      right: -13px;
    }
  }
}
.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;

  list-style: none;
  li {
    display: inline-block;
    margin: 0 5px;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: #fff;
    cursor: pointer;
    button {
      font-size: 0;
      background: none;
      border: none;
    }
    &.slick-active {
      background: $accent-color;
    }
  }
}
