.page-section {
  margin-top: 27px;
  margin-bottom: 60px;
  @media screen and (max-width: $sm) {
    margin-bottom: 68px;
    margin-top: 41px;
  }

  .flex {
    .link {
      white-space: nowrap;
      width: 220px;
      flex-shrink: 0;
      margin-bottom: 22px;
      @media screen and (max-width: $sm) {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }
  }
}

.section-chess {
  @media screen and (max-width: $md) {
    overflow: hidden;

    .flex {
      flex-wrap: wrap;
    }
  }
  .txt-content {
    // padding-right: 46px;
    width: 50%;
    @media screen and (max-width: $md) {
      padding: 0;
      width: 100%;
    }
    ul,
    li,
    p {
      line-height: 1.5;
    }

    .subtitle {
      color: $accent-color;
      font-size: 2rem;
      margin-bottom: 30px;
    }
    .title {
      font-family: "GetVoIP Grotesque";
      font-size: 2.4rem;
      line-height: 30px;
      margin-bottom: 22px;
      text-transform: uppercase;
      @media screen and (max-width: $sm) {
        font-size: 2.1rem;
        line-height: 2.4rem;
        text-transform: none;
      }
    }
  }
  .txt-img {
    width: 50%;
    margin-left: 46px;
    overflow: hidden;
    border-radius: 64px;
    img {
      display: block;
    }
    @media screen and (max-width: $md) {
      width: 100%;
      border-radius: 36px;
      margin-top: 30px;
      margin-bottom: 0px;
      margin-left: 0;
    }
    @media screen and (max-width: $sm) {
      img {
        min-height: calc(100vw - 40px);
        object-fit: cover;
      }
    }
  }
  .fdr {
    img {
      float: right;
    }
    .txt-content {
      margin-left: 25px;
      margin-right: 0;
      @media screen and (max-width: $md) {
        margin-left: 0;
      }
    }
    .txt-img {
      margin-left: 0;
      margin-right: 25px;
      padding-right: 0;
      @media screen and (max-width: $sm) {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
}
