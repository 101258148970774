.page__filter {
  &-inner {
    &-row {
      display: flex;
      align-items: stretch;
      // margin-bottom: 47px;
      margin-bottom: 46px;
      @media screen and (max-width: $sm) {
        flex-direction: column;
        margin-bottom: 0;
      }
    }
    &-add-row {
      margin-bottom: 46px;
      @media screen and (max-width: $sm) {
        margin-bottom: 22px;
      }
    }
    &-item {
      min-width: 200px;
      background: #f7f7f7;
      border-radius: 14px;
      padding: 13px 15px 10px 15px;
      flex-shrink: 0;
      width: 25%;
      position: relative;
      height: 68px;
      @media screen and (max-width: $sm) {
        margin-bottom: 14px;
        width: 100%;
        height: 70px;
      }
    }
    &-item:not(:last-child) {
      width: calc(25% - 20px);
      margin-right: 20px;
      @media screen and (max-width: $sm) {
        width: 100%;
        margin-bottom: 14px;
      }
    }

    &-item--check {
      background: none;
      padding: 0;
      .page__filter-label {
        margin-bottom: 7px !important;
      }
    }
    .form-options {
      display: flex;
      gap: 10px;
    }
    .filter-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background: #f7f7f7;
      border-radius: 14px;
      text-decoration: none;
      font-size: 17px;
      line-height: 22px;
      flex-shrink: 0;
      color: #000;
      &.active {
        color: $accent-color;
      }
    }
  }
  &-header {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 1.5rem;
    @media screen and (max-width: $sm) {
      font-size: 1.1rem;
      margin-bottom: 22px;
    }
    ul {
      list-style: none;

      display: flex;
      align-items: center;
      margin-left: 0;
      padding-left: 0;
      @media screen and (max-width: $sm) {
        display: grid;
        grid-template-columns: 13% 30% 37% 20%;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        align-items: center;
        width: 100%;
      }
      li {
        height: 32px;
        @media screen and (max-width: $sm) {
        }

        &:not(:last-child) {
          border-right: 1px solid $line-color;
          a {
            padding-right: 54px;
            @media screen and (max-width: $sm) {
              padding-right: 0px;
            }
          }
        }

        &:not(:first-child) {
          a {
            padding-left: 54px;
            @media screen and (max-width: $sm) {
              padding-left: 0px;
              display: flex;
              margin: auto;
              align-items: center;
              justify-content: center;
            }
          }
        }
        &:last-child a {
          @media screen and (max-width: $sm) {
            // justify-content: end;
            // display: unset;
          }
        }

        &.active {
          a {
            color: #000;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        height: 100%;
        text-decoration: none;
        color: $line-color;
      }
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    @media screen and (max-width: $sm) {
      margin-top: 22px;
    }
    &-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      @media screen and (max-width: $sm) {
        flex-direction: column;
      }
      .btn {
        font-size: 2rem;
        @media screen and (max-width: $sm) {
          font-size: 1.4rem;
        }
      }
    }
    &-result {
      font-size: 1.7rem;
      margin-left: 45px;
      margin-right: auto;
      @media screen and (max-width: $sm) {
        font-size: 1.4rem;
        margin-left: 0;
        margin-top: 14px;
      }
      a {
        text-decoration: none;
      }
    }
    &-clear {
      text-decoration: none;
      color: #000;
      font-size: 1.4rem;
      padding-right: 25px;
      display: flex;
      align-items: center;
      background: url(../img/light/close.svg) 100% 50% no-repeat;
      margin-left: 45px;
      &:hover {
        background: url(../img/light/close-h.svg) 100% 50% no-repeat;
      }
    }
    .page-category__filter-open {
      display: flex;
      align-items: center;
      position: relative;
      padding-right: 40px;
      cursor: pointer;
      @media screen and (max-width: $sm) {
        position: absolute;
        right: 0;
        top: -28px;
        font-size: 1.1rem;
      }
      &.active {
        .icon-arrow-select {
          background: url(../img/light/arrow-up.svg) 100% 50% no-repeat;
        }
      }
    }
  }
  .btn {
    align-self: center;
    @media screen and (max-width: $sm) {
      align-self: start;
      height: 42px;
      font-size: 1.4rem;
    }
  }
  &-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $gray;
    border-radius: 14px;
    z-index: 3000;
    padding: 35px 30px;
    border: 1px solid #9e9e9e;
    overflow: hidden;
    min-width: 500px;
    max-width: 510px;
    @media screen and (max-width: $sm) {
      padding: 30px 20px;
      width: 95%;
      min-width: auto;
    }
    .modal-content {
      display: flex;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 2.2rem;
      font-size: 1.7rem;
       @media screen and (max-width: $sm) {
         line-height: 1.6rem;
          font-size: 1.4rem;
       }
      & + ul{
        margin-left: 100px;
        @media screen and (max-width: $sm) {
          margin-left: 50px;
        }
      }
   
      li {
        padding: 6px 0px;
        a {
          text-decoration: none;
          color: #000;
          &:hover {
            color: $accent-color;
          }
        }
        &.active {
         
          a {
            color: $accent-color;
            background: url(../img/light/close.svg) 100% 6px no-repeat;
            background-size: 12px 12px;
            padding-right: 20px;
            display: inline-block;
            &:hover {
              background: url(../img/light/close-h.svg) 100% 6px no-repeat;
              background-size: 12px 12px;
            }
            @media screen and (max-width: $sm) {
              background: url(../img/light/close.svg) 100% 4px no-repeat;
              background-size: 11px;
            }
          }
        }
      }
      ul {
        margin-top: 3px;
        margin-bottom: 3px;
   
    
        li {
          padding: 6px 15px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    h4 {
      margin-bottom: 10px;
      margin-top: 5px;
      font-size: 2rem;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      height: 25px;
      width: 25px;
      align-items: center;
      background: url(../img/light/close.svg) 100% 50% no-repeat;
    }
    &-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      @media screen and (max-width: $sm) {
       justify-content: space-between;
       }
      .btn {
        padding: 5px 20px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
      }
      .page__filter-footer-clear {
        right: 30px;
        position: absolute;
        @media screen and (max-width: $sm) {
         position: unset;
        }
      }
      
    }
  }
}

.custom-select {
  background: #f7f7f7;
  border-radius: 14px;
  padding: 15px;
}
.page_main .page__filter-finishing {
  width: 180px !important;
  flex-grow: 0 !important;
  @media screen and (max-width: $sm) {
    width: unset !important;
    flex-grow: unset !important;
  }
}
@media screen and (max-width: $sm) {
  .custom-select.mobile {
    padding: 0;
  }
}
.page__filter-lot {
  background: none;
  padding: 0;
  width: 25%;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: $sm) {
    margin-bottom: 0px;
    width: 100%;
    align-items: flex-start;
  }
  .page__filter-label {
    padding-top: 15px;
  }
  input[type="text"] {
    width: 90%;
  }
}
input[type="text"] {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $line-color;
  padding: 10px;
  margin-bottom: 1px;
  line-height: 28px;
  font-size: 1.7rem;
  @media screen and (max-width: $sm) {
    font-size: 1.5rem;
  }
}

.page__filter-label {
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: $line-color;
  display: block;
  margin-bottom: 0 !important;
  @media screen and (max-width: $sm) {
    font-size: 1.2rem;
  }
}

.icon-arrow-select {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../img/light/arrow-down.svg) 100% 50% no-repeat;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.page_main .page__filter {
  margin-bottom: 87px;
  margin-top: 30px;
  @media screen and (max-width: $sm) {
    margin-top: 0;
    margin-bottom: 68px;
  }
  .page_main .page__filter-inner-row {
    justify-content: space-between;
  }
  .page__filter-inner-item {
    width: auto;
    min-width: auto;
    flex-grow: 1;
    position: relative;
    @media screen and (max-width: $sm) {
      margin-bottom: 14px;
      height: 70px;
      margin-right: 0;
    }
  }
  &-footer {
    &-buttons {
      width: auto;
      flex-wrap: wrap;
      @media screen and (max-width: $sm) {
        flex-direction: column;
      }
    }
    &-clear {
      margin-left: 0;
    }
    &-result {
      width: auto;
      margin-left: 65px;
      margin-top: 0;
      font-size: 1.7rem;
      @media screen and (max-width: $sm) {
        margin-left: 0 !important;
        font-size: 1.4rem;
      }
      a {
        text-decoration: none;
      }
    }
  }
}

.page-category__label-content {
  .flex {
    justify-content: space-between;
    font-size: 1.7rem;
    margin-top: 3px;
    @media screen and (max-width: $sm) {
      font-size: 1.5rem;
    }
  }
}
