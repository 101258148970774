.modal {
  background: #f7f7f7;
  border: 1px solid #9e9e9e;
  border-radius: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 661px;
  padding: 51px 28px;
  z-index: 100;
  &_form__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
    padding-right: 73px;
    @media screen and (max-width: $sm) {
      padding-right: 0;
    }
  }
  &_form__field {
    position: relative;
    width: 48%;
    @media screen and (max-width: $sm) {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .input_text {
      background: #ffffff;
      border-radius: 42px;
      border: none;
      padding: 10px 20px;
      width: 100%;
    }
    input:focus ~ span {
      display: none;
    }
    span {
      font-size: 1.5rem;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      @media screen and (max-width: $sm) {
        font-size: 1.3rem;
      }
    }
  }
}

.modal_presentation {
  &-title {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .fancybox-button {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 20px;
    right: 20px;
    background: url(../img/modal-close.svg) no-repeat;
  }
}

.modal_towers {
  position: absolute;
  top: 78px;
  right: 32px;
  background: #ffffff;
  border: 1px solid #9e9e9e;
  border-radius: 14px;
  padding: 52px 28px 35px;
  width: 411px;

  &-title {
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 44px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }
  &-params {
    display: flex;
    gap: 25px;
    align-items: center;
    margin-bottom: 14px;
    .params-item {
      display: flex;
      align-items: center;
      &-text {
        font-weight: 800;
        font-size: 12px;
        line-height: 18px;
        margin-left: 12px;
      }
    }
  }
  &-links {
    display: flex;
    margin-top: 35px;
    gap: 40px;
    ul {
      list-style: none;
      font-weight: 800;
      font-size: 12px;
      line-height: 27px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      li {
        margin-bottom: 0px;
      }
      a {
        text-decoration: none;
        color: #000;
      }
      span {
        color: $accent-color;
      }
    }

    h4 {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    .col {
      &:not(:first-child) {
        border-left: 1px solid #000000;
        padding-left: 30px;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
  }

  .btn {
    margin-top: 35px;
    padding: 10px 49px;
  }
  &-close {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 20px;
    right: 20px;
    background: url(../img/modal-close.svg) no-repeat;
    cursor: pointer;
  }
}
