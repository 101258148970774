.page-contact {
  &-row__info {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 35px;
    @media screen and (max-width: $sm) {
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  &-row__col {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media screen and (max-width: $sm) {
      margin-bottom: 30px;
    }
  }
  &__address {
    line-height: 1.4;
  }
  &__title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 35px;
    @media screen and (max-width: $sm) {
      font-size: 1.7rem;
    }
  }
  &__social {
    margin-top: auto;
    @media screen and (max-width: $sm) {
      margin-top: 24px;
    }
    p {
      margin-bottom: 15px;
    }
    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      &:hover {
        svg path {
          fill: $accent-color !important;
        }
      }
      &.fav {
        &:hover {
          svg path {
            fill: none !important;
            stroke: $accent-color;
          }
        }
      }
    }
  }
  &__site {
    display: block;
    // margin-top: 20px;
    font-size: 2rem;
    text-decoration: none;
    margin-bottom: 40px;
    @media screen and (max-width: $sm) {
      font-size: 1.7rem;
      margin-bottom: 0px;
    }
  }
  &__mail {
    font-size: 2rem;
    text-decoration: none;
    @media screen and (max-width: $sm) {
      font-size: 1.7rem;
    }
  }
  &__phone {
    color: #000;
    font-size: 2rem;
    margin-top: 5px;
    @media screen and (max-width: $sm) {
      font-size: 1.7rem;
    }
  }

  &__map {
    margin-bottom: 86px;
    height: 427px;
    @media screen and (max-width: $sm) {
      margin-bottom: 62px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  @media screen and (max-width: $sm) {
    p {
      max-width: 230px;
    }
  }
}
.screen-contacts__map {
  position: relative;
  width: 100%;
  height: 100%;
}
