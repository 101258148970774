.page-about {
  .section-chess {
    margin-bottom: 0;
    @media screen and (max-width: $md) {
      margin-bottom: 0;
    }
  }
  &-txt-img,
  &-txt-content {
    width: 50%;
    @media screen and (max-width: $md) {
      width: 100%;
      padding: 0 !important;
    }
  }
  &-txt-img {
    padding-left: 26px;
    @media screen and (max-width: $sm) {
      margin-bottom: 0;
      margin-top: 41px;
      height: 240px;
      object-fit: cover;
    }
    @media screen and (max-width: 945px) and (min-width: $sm) {
      margin-top: 22px;
    }
    img {
      border-radius: 64px;
      @media screen and (max-width: $md) {
        width: 100%;
        border-radius: 36px;
      }
      @media screen and (max-width: 945px) and (min-width: $sm) {
        max-height: 70vh;
      }

      @media screen and (max-width: $sm) {
        // border-radius: 0px;
        width: 100%;
        max-width: 100%;
        height: 240px;
        object-fit: cover;
      }
    }
  }
  .fdr {
    .page-about-txt-img {
      padding-right: 26px;
    }
  }
  .section-feedback {
    margin-top: 75px;
    @media screen and (max-width: $sm) {
      margin-top: 68px;
    }
  }
}
