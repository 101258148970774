.page-product {
  &-content__price-wrapper {
    .price {
      display: block;
      font-family: "GetVoIP Grotesque";
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.2rem;
      margin-top: 10px;
      margin-bottom: 7px;
    }
    .desc {
      font-size: 1.5rem;
      @media screen and (max-width: $sm) {
        font-size: 1.4rem;
      }
    }
    .row {
      display: flex;
      margin-bottom: 35px;
      justify-content: space-between;
      .currency {
        font-size: 1.4rem;
        span {
          display: inline-block;
          margin: 0 4px;
        }
        a {
          display: inline-block;
          margin: 0 4px;
          text-decoration: none;
        }
        @media screen and (max-width: $sm) {
        }
      }
      .desc {
        span {
          color: $accent-color;
        }
      }
    }
  }
  &-content__row {
    margin-top: 45px;
    margin-bottom: 36px;
    justify-content: space-between;
    @media screen and (max-width: $sm) {
      margin-top: 36px;
    }
  }
  &-content__floor,
  &-content__square {
    margin-right: 0px;
    @media screen and (max-width: $sm) {
      margin-right: 67px;
    }
    span {
      display: block;
      font-family: "GetVoIP Grotesque";
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.2rem;
      margin-top: 10px;
    }
  }
  &-content__actions {
    margin-top: 50px;
    @media screen and (max-width: $sm) {
      margin-top: 41px;
      margin-bottom: 0px;
    }
    .btn {
      margin-bottom: 22px;
      font-size: 1.4rem;
      @media screen and (max-width: $sm) {
        &:last-child {
          margin-bottom: 0;
        }
        display: block;
        text-align: center;
      }
      &:hover {
        color: $accent-color;
        border-color: $accent-color;
      }
    }
  }
  &-content__title {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.4;
    margin-bottom: 36px;
    @media screen and (max-width: $sm) {
      margin-bottom: 41px;
    }
  }
  &-content__details {
    width: 50%;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-right: 20px;
    @media screen and (max-width: $sm) {
      width: 100%;
      margin-bottom: 22px;
    }
    &-item {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      font-size: 1.6rem;
      font-weight: 500;
      @media screen and (max-width: $sm) {
        font-size: 1.1rem;
        margin-bottom: 22px;
      }
      &:last-child {
        width: 100%;
        padding-right: 30px;
      }
      &-icon {
        width: 68px;
        height: 68px;
        background: #f7f7f7;
        border-radius: 14px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        @media screen and (max-width: $sm) {
          margin-right: 11px;
          width: 56px;
          height: 56px;
        }
      }
    }
  }
  .txt-content {
    h4.title {
      font-family: "GetVoIP Grotesque";
      font-size: 3rem;
      text-transform: uppercase;
      margin-bottom: 40px;
      @media screen and (max-width: $sm) {
        font-size: 2.1rem;
        margin-bottom: 22px;
        text-transform: none;
      }
    }
    .btn {
      margin-top: 30px;
    }
  }
  .section-chess {
    @media screen and (max-width: $md) {
      margin-bottom: 68px;
    }
  }
  &-txt-img,
  &-txt-content {
    width: 50%;
    @media screen and (max-width: $md) {
      width: 100%;
      padding: 0 !important;
      .btn {
        margin-top: 21px;
      }
    }
  }
  &-txt-img {
    padding-left: 46px;
    @media screen and (max-width: $sm) {
      margin-bottom: 41px;
      margin-top: 41px;
      height: 240px;
      object-fit: cover;
    }
    img {
      border-radius: 64px;
      @media screen and (max-width: $md) {
        width: 100%;
        border-radius: 36px;
      }
      @media screen and (max-width: $sm) {
        width: 100%;
        max-width: 100%;
        height: 240px;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: $md) {
    .section-chess {
      .flex {
        // flex-direction: column-reverse;
        .front-page-txt-img {
          margin-bottom: 41px;
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          img {
            border-radius: 36px;
          }
        }
      }
    }
  }
  .page-breadcrumbs {
    margin: 35px 0 38px;
  }
  .section-feedback {
    margin-top: 66px;
    @media screen and (max-width: $sm) {
      margin-top: unset;
    }
  }
  .page-category {
    margin-bottom: 68px;
  }
}

.paging {
  display: flex;
  align-items: center;
  @media screen and (max-width: $sm) {
    margin-bottom: 46px;
  }
}
.pages {
  margin-left: 40px;
  font-size: 12px;
}
