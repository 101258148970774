@media screen and (max-width: $sm) {
  .header.open {
    .main-nav {
      height: calc(100vh - 79px);
      // height: 100%;
      // height: fill-available;
      // height: stretch;
      // height: 100vh;
      background: #fff;
      width: 100%;
      position: absolute;
      left: 0;
      top: 79px;
      display: flex;
      flex-direction: column;
      padding-bottom: 80px;
      justify-content: space-between;
    }
    .header__top-nav {
      display: block !important;
      margin: 20px;

      li {
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 20px;
        font-size: 1.4rem;
        margin-bottom: 10px;
        background-color: $gray;
        border-radius: 14px;
        font-weight: bold;
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
        a {
          border: none;
          padding: 10px 0;
        }
        a.sub {
          background: url(../img/light/arrow-down.svg) 100% 14px no-repeat;
        }
        .submenu {
          li {
            font-weight: normal;
            margin-bottom: 0;
            a {
              padding: 5px 0;
            }
          }
        }
        &.active {
          & > a {
            color: #999;
            background: url(../img/light/arrow-up.svg) 100% 50% no-repeat;
            &::before {
              display: none;
            }
          }
          .submenu {
            display: block;
            position: relative;
            margin-left: 0;
            padding-top: 0;
            li {
              padding-left: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .add-nav {
      display: none !important;
    }
    .header_mobile {
      &__info {
        display: block;
        margin-left: 20px;
        // margin-top: auto;
        // margin-top: 60px;
        padding-bottom: 30px;
      }
    }
  }
}
