.page-product-order {
  @media screen and (max-width: $sm) {
    margin-top: 68px;
  }

  &-row {
    background: #f7f7f7;
    border-radius: 14px;
    padding: 33px 28px;
    display: flex;
    align-items: center;
    @media screen and (max-width: $sm) {
      flex-direction: column;
      padding: 41px 20px;
      align-items: flex-start;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  &-form__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
    padding-right: 73px;
    @media screen and (max-width: $sm) {
      padding-right: 0;
    }
  }
  &-form__field {
    position: relative;
    width: 48%;
    @media screen and (max-width: $sm) {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .input_text {
      background: #ffffff;
      border-radius: 42px;
      border: none;
      padding: 10px 20px;
      width: 100%;
    }

    input:focus ~ span {
      display: none;
    }
    span {
      font-size: 1.5rem;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      @media screen and (max-width: $sm) {
        font-size: 1.3rem;
      }
    }
  }

  .subscribe-form__title {
    font-size: 25px;
    margin-bottom: 41px;
    font-weight: bold;
    @media screen and (max-width: $sm) {
      font-size: 1.9rem;
    }
  }
  .form__control {
    background: #ffffff;
    border-radius: 42px;
    border: none;
    padding: 9px 20px;
    width: 42%;

    & + .form__control {
      margin-left: 30px;
      @media screen and (max-width: $sm) {
        margin-left: 0;
      }
    }
    @media screen and (max-width: $sm) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
  &-form {
    width: 71%;
    @media screen and (max-width: $sm) {
      width: 100%;
    }
  }
  &-contacts {
    padding: 0;
    @media screen and (max-width: $sm) {
      margin-top: 40px;
    }
    p {
      font-size: 1.4rem;
    }
    &-img {
      border-radius: 100px;
      margin-right: 40px;

      width: 120px;
    }
    &-manager {
      font-size: 2rem;
      line-height: 35px;
    }
    &-social {
      margin-top: 10px;
      .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 16px;
        @media screen and (max-width: $sm) {
          width: 24px;
          height: 24 px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          svg path {
            fill: $accent-color !important;
          }
        }
      }
    }
    &-img {
      width: 99px;
      height: 99px;
      margin-bottom: 17px;
      margin-top: 10px;
      @media screen and (max-width: $sm) {
        margin-top: 0;
      }
    }
    &-phone {
      color: #000;
      text-decoration: none;
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 10px;
      display: block;
      @media screen and (max-width: $sm) {
        font-size: 1.7rem;
        margin-left: 0;
      }
    }
  }

  .order-form__checkbox {
    display: block;
    margin-top: 23px;
    margin-bottom: 37px;
    position: relative;
    @media screen and (max-width: $sm) {
      margin-top: 14px;
      margin-bottom: 18px;
    }
  }
}

.page-product-contacts {
  background: #f7f7f7;
  border-radius: 14px;
  padding: 40px 47px;
  @media screen and (max-width: $sm) {
    padding: 41px 20px;
    margin: 0 -20px;
  }
  &-inner {
    display: flex;
    align-items: center;
    @media screen and (max-width: $sm) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
    }
    .page-product-order-social,
    .page-product-order-phone {
      margin-left: 70px;
      @media screen and (max-width: $sm) {
        margin-left: 0;
      }
    }
  }
  &-img {
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
  }
  &-manager {
    font-size: 2rem;
    margin-left: 29px;
    @media screen and (max-width: $sm) {
      font-size: 1.7rem;
      margin-top: 10px;
      margin-left: 0;
    }
    span {
      display: block;
      font-size: 1.4rem;
      line-height: 2.2rem;
      @media screen and (max-width: $sm) {
        font-size: 1.2rem;
      }
    }
  }
  &-title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 35px;
    margin-bottom: 40px;

    @media screen and (max-width: $sm) {
      margin-left: 0;
      font-size: 1.9rem;
      margin-bottom: 22px;
    }
  }
  &-phone {
    color: #000;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 12px;
    margin-left: 99px;
    display: block;
    @media screen and (max-width: $sm) {
      font-size: 1.7rem;
      margin-left: 0;
    }
  }

  &-social {
    margin-top: 10px;
    margin-left: 68px;
    @media screen and (max-width: $sm) {
      margin-left: 0;
    }
    .icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 16px;
      svg {
        width: 100%;
        height: 100%;
      }
      &:hover {
        svg path {
          fill: $accent-color !important;
        }
      }
    }
  }
}

[data-form-ok] {
  display: none;
  margin-bottom: 30px;
}
